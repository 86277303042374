import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    loaded: null,
  },
  reducers: {
    setAppLoaded(state) {
      state.loaded = true;
    },
  },
});

export const {
  setAppLoaded,
} = appSlice.actions;

export default appSlice.reducer;
