import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '~components/Button';
import Input from '~components/Input';
import Spinner from '~components/Spinner';
import { Caption, Heading, Paragraph } from '~components/Typography';

import { getMerchantInfo } from '~store/actions/request';
import { merchantSelector } from '~store/reducers/merchant';
import { requestSelector, setCreditAmount } from '~store/reducers/request';
import useScrollRestoration from '~shared/helpers/hooks/useScrollRestoration';

import { startPage } from '~data/en';

import * as styles from './Start.module.scss';

function Start() {
  useScrollRestoration();

  const dispatch = useDispatch();
  const { creditAmount } = useSelector(requestSelector);
  const { loading, id, logo, name } = useSelector(merchantSelector);

  const handleAmountChange = (e) => {
    dispatch(setCreditAmount(e.target.value));
  };

  useEffect(() => {
    if (id && !logo && !loading) {
      dispatch(getMerchantInfo(id));
    }
  }, []);

  return (
    <div className={ styles.start }>
      <div className={ styles.container }>

        <div className={ styles.partner }>
          <Caption
            className={ styles.caption }
            align="center"
            html={ startPage.partner.description }
          />

          <div className={ styles.partnerCard }>
            <div className={ styles.partnerLogoWrapper }>
              {
                loading ? (
                  <Spinner label={ startPage.partner.pending } />
                ) : (
                  <img
                    src={ logo }
                    height="48"
                    className={ styles.partnerLogo }
                    alt={ name }
                  />
                )
              }
            </div>

            <p
              className={ styles.partnerDescription }
              dangerouslySetInnerHTML={ { __html: startPage.partner.description2 } }
            />
          </div>
        </div>

        <div className={ styles.content }>
          <Heading
            type="h1"
            align="center"
            html={ startPage.heading }
          />

          <Paragraph
            marginTop="1"
            marginBottom="2"
            align="center"
            html={ startPage.description }
          />

          <div className={ styles.field }>
            <Input
              type="number"
              startAdornment="€"
              align="right"
              value={ creditAmount }
              onChange={ handleAmountChange }
            />
          </div>
        </div>
      </div>

      <div className={ styles.button }>
        <Button
          to="/auth/signin"
          disabled={ !creditAmount || !id }
          block
        >
          { startPage.submit }
        </Button>
      </div>
    </div>
  );
}

export default Start;
