import PropTypes from 'prop-types';

import * as styles from './Switch.module.scss';

function Option({ option, name, onChange, className }) {
  return (
    <label key={ option } className={ className }>
      { option }
      <input className="visually-hidden" type="radio" name={ name } onChange={ onChange } />
    </label>
  );
}

Option.propTypes = {
  className: PropTypes.string,
  option: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

Option.defaultProps = {
  className: styles.option
};

export default Option;
