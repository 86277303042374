import axios from 'axios';

import { API } from '~shared/constants';

import initCustomerApi from './customer';
import initMerchantApi from './merchant';
import initRequestApi from './request';

const instance = axios.create({
  baseURL: API.baseUrl,
  timeout: 3000,
  headers: { 'Content-Type': 'application/json' },
});

export default {
  merchant: initMerchantApi(instance),
  customer: initCustomerApi(instance),
  request: initRequestApi(instance)
};
