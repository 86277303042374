import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';

import Button from '~components/Button';
import { CheckboxField } from '~components/Checkbox';
import ErrorModal from '~components/ErrorModal';
import { InputField } from '~components/Input';
import { useLoader } from '~components/Loader';
import { SelectField } from '~components/Select';
import { Heading } from '~components/Typography';

import { createCustomer } from '~store/actions/customer';
import { clearCustomerErrors, customerSelector } from '~store/reducers/customer';
import useScrollRestoration from '~shared/helpers/hooks/useScrollRestoration';

import { ERROR } from '~shared/constants';
import { additionalInfo } from '~data/en';

import validationSchema from './validationSchema';

import * as styles from './AdditionalInfo.module.scss';

const initialValues = {
  city: '',
  zip: '',
  street: '',
  occupation: null,
  monthlyIncome: '',
  monthlyExpenses: '',
  coreBank: null,
  pep: false,
  consent: false,
};

function AdditionalInfo() {
  useScrollRestoration();

  const dispatch = useDispatch();
  const history = useHistory();
  const { setLoading } = useLoader();

  const {
    error,
    loading,
    phone,
    email,
    authSessionId,
  } = useSelector(customerSelector);

  const handleSubmit = (values, actions) => {
    setLoading(true);

    dispatch(createCustomer({
      phone,
      email,
      authSessionId,
      ...values
    }))
      .then((res) => {
        if (res.payload.error) return;

        actions.resetForm();
        history.push('/contract/initial-payment');
      })
      .finally(() => {
        setLoading(false);
        actions.setSubmitting(false);
      });
  };

  const handleErrorModalClose = () => {
    dispatch(clearCustomerErrors());
  };

  useEffect(() => {
    if (!error) {
      return;
    }

    switch (error.code) {
      case ERROR.EMAIL_EXISTS:
      case ERROR.EMAIL_INVALID:
      case ERROR.SESSION_EXPIRED:
        history.push('/auth/signin');

        break;
      default:
    }
  }, [ error ]);

  return (
    <div className={ styles.info }>
      <Formik
        validateOnMount
        initialValues={ initialValues }
        validationSchema={ validationSchema }
        onSubmit={ handleSubmit }
      >
        {
          ({ isValid, isSubmitting }) => (
            <Form className={ styles.form }>
              <div className={ styles.fieldset }>
                <Heading
                  type="legend"
                  size="h2"
                  marginBottom="2"
                  html={ additionalInfo.heading }
                />

                <div className={ styles.group }>
                  <InputField
                    name="city"
                    placeholder={ additionalInfo.fields.city.label }
                  />
                </div>

                <div className={ styles.group }>
                  <InputField
                    name="street"
                    placeholder={ additionalInfo.fields.street.label }
                  />
                </div>

                <div className={ styles.group }>
                  <InputField
                    name="zip"
                    placeholder={ additionalInfo.fields.zip.label }
                  />
                </div>

                <div className={ styles.group }>
                  <InputField
                    name="monthlyIncome"
                    type="number"
                    placeholder={ additionalInfo.fields.monthlyIncome.label }
                  />
                </div>

                <div className={ styles.group }>
                  <InputField
                    name="monthlyExpenses"
                    type="number"
                    placeholder={ additionalInfo.fields.monthlyExpenses.label }
                  />
                </div>

                <div className={ styles.group }>
                  <SelectField
                    name="occupation"
                    placeholder={ additionalInfo.fields.occupation.label }
                    options={ additionalInfo.fields.occupation.options }
                  />
                </div>
              </div>

              <div className={ styles.fieldset }>
                <Heading
                  type="legend"
                  size="h2"
                  marginBottom="2"
                  html={ additionalInfo.heading2 }
                />

                <div className={ styles.group }>
                  <SelectField
                    name="coreBank"
                    placeholder={ additionalInfo.fields.coreBank.label }
                    options={ additionalInfo.fields.coreBank.options }
                    renderOption={
                      (option, onSelect) => (
                        <button
                          type="button"
                          key={ option.value }
                          className={ styles.option }
                          onClick={ onSelect }
                        >
                          {
                            option.img ? (
                              <img
                                src={ option.img[1] }
                                srcSet={ `${ option.img[0] } 2x, ${ option.img[1] }` }
                                alt={ option.label }
                                height="48"
                              />
                            ) : option.label
                          }
                        </button>
                      )
                    }
                    renderSelectedOption={
                      (option) => (
                        <span
                          className={ styles.box }
                        >
                          {
                            option.img ? (
                              <img
                                src={ option.img[1] }
                                srcSet={ `${ option.img[0] } 2x, ${ option.img[1] }` }
                                alt={ option.label }
                                height="48"
                              />
                            ) : option.label
                          }
                        </span>
                      )
                    }
                  />
                </div>

                <div className={ styles.groupCheckbox }>
                  <CheckboxField name="pep">
                    { additionalInfo.fields.pep.label }
                  </CheckboxField>
                </div>

                <div className={ styles.groupCheckbox }>
                  <CheckboxField name="consent">
                    { additionalInfo.fields.consent.label }
                  </CheckboxField>
                </div>
              </div>

              <div className={ styles.submit }>
                <Button
                  type="submit"
                  disabled={ loading || !isValid || isSubmitting }
                  block
                >
                  { additionalInfo.submit }
                </Button>
              </div>
            </Form>
          )
        }
      </Formik>

      <ErrorModal
        error={ error }
        onClose={ handleErrorModalClose }
      />
    </div>
  );
}

export default AdditionalInfo;
