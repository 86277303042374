import { ERROR } from '~shared/constants';
import { errors } from '~data/en';

const initCustomerApi = (instance) => ({
  authStart: async (personalId) => {
    if (!personalId) return;

    try {
      const res = await instance.post('/customers/customer/v1/auth_start/', {
        personal_id: personalId
      });

      return {
        authSessionId: res.data.auth_session_id,
        verificationCode: res.data.verification_code
      };
    } catch (e) {
      if (e.response.status === 500 && e.response.data.auth_start_error) {
        return {
          error: errors[ERROR.AUTH_ERROR],
        };
      }

      return {
        error: errors[ERROR.NETWORK],
      };
    }
  },
  getAuthStatus: async (authSessionId) => {
    const res = await instance.post('/customers/customer/v1/auth_status/', {
      auth_session_id: authSessionId
    });

    return res.data;
  },
  createCustomer: async (data) => {
    const {
      authSessionId,
      monthlyIncome,
      monthlyExpenses,
      occupation,
      coreBank,
      ...restData
    } = data;

    try {
      const res = await instance.post('/customers/customer/v1/', {
        ...restData,
        auth_session_id: authSessionId,
        monthly_income: monthlyIncome,
        monthly_expenses: monthlyExpenses,
        occupation: occupation.value,
        core_bank: coreBank.value
      });

      return res.data;
    } catch (e) {
      if (e.response.status !== 400) {
        return {
          error: errors[ERROR.NETWORK],
        };
      }

      const responseErrors = Object.keys(e.response.data).map((key) => {
        if (key === 'auth_session_id') {
          return {
            error: errors[ERROR.SESSION_EXPIRED]
          };
        }

        if (key === 'email' && e.response.data[key].includes('already_exists')) {
          return {
            error: errors[ERROR.EMAIL_EXISTS]
          };
        }

        if (key === 'email') {
          return {
            error: errors[ERROR.EMAIL_INVALID]
          };
        }

        return {
          error: errors[ERROR.MISSING_REQUIRED_PARAM](errors.customerParams[key])
        };
      });

      return responseErrors[0];
    }
  }
});

export default initCustomerApi;
