/* eslint-disable import/prefer-default-export */

export const API = {
  baseUrl: 'https://stage.finamba.com/api'
};

export const ERROR = {
  UNKNOWN: 'ERROR/UNKNOWN',
  NOT_FOUND: 'ERROR/NOT_FOUND',
  NETWORK: 'ERROR/NETWORK',
  AUTH_ERROR: 'ERROR/AUTH_ERROR',
  VERIFICATION_ERROR: 'ERROR/VERIFICATION_ERROR',
  SESSION_EXPIRED: 'ERROR/SESSION_EXPIRED',
  EMAIL_EXISTS: 'ERROR/EMAIL_EXISTS',
  EMAIL_INVALID: 'ERROR/EMAIL_INVALID',
  MISSING_REQUIRED_PARAM: 'ERROR/MISSING_REQUIRED_PARAM'
};
