/* eslint-disable no-irregular-whitespace */
import { ERROR } from '~shared/constants';

import citadele from '~assets/images/citadele.png';
import citadele2x from '~assets/images/citadele@2x.png';
import facebookIcon from '~assets/images/facebook.svg';
import googleIcon from '~assets/images/google.svg';
import luminor from '~assets/images/luminor.png';
import luminor2x from '~assets/images/luminor@2x.png';
import notFoundPicture from '~assets/images/not-found.svg';
import onboardingPicture1 from '~assets/images/onboarding1.svg';
import onboardingPicture2 from '~assets/images/onboarding2.svg';
import onboardingPicture3 from '~assets/images/onboarding3.svg';
import onboardingPicture4 from '~assets/images/onboarding4.svg';
import rejectedPicture from '~assets/images/rejected.svg';
import seb from '~assets/images/seb.png';
import seb2x from '~assets/images/seb@2x.png';
import smartId from '~assets/images/smart-id.png';
import smartId2x from '~assets/images/smart-id@2x.png';
import successPicture from '~assets/images/success.svg';
import sweedBank from '~assets/images/sweedbank.png';
import sweedBank2x from '~assets/images/sweedbank@2x.png';
import backLinkPicture from '~assets/images/wait.svg';

const data = {
  meta: {
    // also you should change default values in public/index.html
    title: 'finamba',
    description: 'Split the payments quick & easy'
  },
  header: {
    logoAlt: 'finamba',
    authOptions: [
      {
        label: 'Register',
        path: '/auth/register'
      }, {
        label: 'Sign In',
        path: '/auth/signin'
      }
    ]
  },
  onboarding: {
    screens: [
      {
        picture: {
          src: onboardingPicture1,
          alt: 'The man looks at the phone'
        },
        heading: `
          Split the payment
          <br />
          quick & easy
        `,
      },
      {
        picture: {
          src: onboardingPicture2,
          alt: 'A man sitting in a chair'
        },
        heading: `
          Pay later after 14 or 30
          days or split into 36 payments
          <br />
          (or less)
        `
      },
      {
        picture: {
          src: onboardingPicture3,
          alt: 'A man holding a credit card'
        },
        heading: `
          No credit card
          <br />
          is required
        `
      },
      {
        picture: {
          src: onboardingPicture4,
          alt: 'People talk to each other'
        },
        heading: `
          Get the best price
          <br />
          from our partners
        `
      }
    ]
  },
  startPage: {
    partner: {
      description: 'in partnership with',
      description2: 'Allows you to have procedure now and pay later',
      pending: 'Fetching merchant data...'
    },
    heading: 'Invoice amount',
    description: 'Please indicate the price of the procedure you want to split in payments',
    submit: 'Split It'
  },
  auth: {
    heading: 'Let’s sign you in!',
    description: `
      Please indicate your e-mail,
      <br />
      phone and personal number
    `,
    fields: {
      email: {
        label: 'Email Address',
      },
      phone: {
        label: 'Phone Number',
        mask: '+00000[0000000000]'
      },
      personalId: {
        label: 'Personal Number',
        mask: '000000-`00000'
      }
    },
    submit: 'Sign In',
    socials: {
      label: 'Or sign in with',
      options: [
        {
          id: 'google',
          label: 'Google',
          url: '',
          icon: googleIcon
        },
        {
          id: 'facebook',
          label: 'Facebook',
          url: '',
          icon: facebookIcon
        },
      ]
    }
  },
  verificationPage: {
    heading: 'Verify your identity please',
    description: 'Choose one of the available identity tools and start the process',
    providers: [
      {
        id: 'smartid',
        picture: [ smartId2x, smartId ],
        label: 'Smart-ID'
      },
      // {
      //   id: 'sweedbank',
      //   picture: [ sweedBank2x, sweedBank ],
      //   label: 'Sweedbank'
      // },
      // {
      //   id: 'citadele',
      //   picture: [ citadele2x, citadele ],
      //   label: 'Citadele'
      // },
      // {
      //   id: 'seb',
      //   picture: [ seb2x, seb ],
      //   label: 'SEB'
      // }
    ],
    submit: 'Verify Identity'
  },
  additionalInfo: {
    heading: 'Additional information',
    heading2: `
      Pick your core bank
      <br />
      (your daily bank account is located)
    `,
    submit: 'Get this product',
    fields: {
      city: {
        label: 'City'
      },
      street: {
        label: 'Street'
      },
      zip: {
        label: 'ZIP'
      },
      monthlyIncome: {
        label: 'Monthly Income, €'
      },
      monthlyExpenses: {
        label: 'Monthly Expenses, €'
      },
      occupation: {
        label: 'Occupation',
        options: [
          {
            value: 1,
            label: 'Test1',
          },
          {
            value: 2,
            label: 'Test2'
          }
        ],
      },
      coreBank: {
        label: 'Select',
        options: [
          {
            value: 1,
            label: 'SweedBank',
            img: [ sweedBank2x, sweedBank ]
          },
          {
            value: 2,
            label: 'Citadele',
            img: [ citadele2x, citadele ]
          },
          {
            value: 3,
            label: 'SEB',
            img: [ seb2x, seb ]
          },
          {
            value: 4,
            label: 'Luminor',
            img: [ luminor2x, luminor ]
          },
          {
            value: 5,
            label: 'Other bank',
          }
        ]
      },
      pep: {
        label: 'Are you politically exposed person?'
      },
      consent: {
        label: 'Do you agree that you are the person which is indicated in the application and do you agree that your data will be proceeded through debt history data basis, income authority, etc.'
      }
    }
  },
  initialPayment: {
    totalAmount: 'Total amount',
    heading: 'Initial Payment',
    label: 'will be:',
    currency: '€',
    heading2: 'Or fill in different sum',
    percentageOptions: [ '0%', '10%', '20%', '30%' ],
    splitOptions: [ 'by percentage', 'by amount' ],
    submit: 'Split Payment'
  },
  backLink: {
    picture: {
      src: backLinkPicture,
      alt: 'Sandglass'
    },
    description: 'Some banks are still preparing an offer, please use this link to come back later and choose your offer (additionally, the link will be sent to your e-mail)'
  },
  offersPage: {
    heading: 'Split payment',
    offer: {
      time: {
        months: 'months',
        days: 'days'
      },
      amount: 'Payment from:',
      currency: '€',
      withInterest: 'with interest'
    },
    submit: 'Split payment'
  },
  contractSigning: {
    heading: 'Contract Signing',
    summary: {
      heading: 'Offer Summary',
      product: 'Product:',
      monthlyPayment: 'Monthly payment:',
      currency: '€',
      paymentsCount: 'Number of payments:',
      nextPaymentDate: 'Next payment date:',
      apr: 'APR'
    },
    documentsForm: {
      heading: 'Document for identification',
      fields: {
        documentType: {
          label: 'Document type',
          options: [
            {
              label: 'Passport',
              value: 1
            },
            {
              label: 'ID Card',
              value: 2
            }
          ]
        },
        documentNumber: {
          label: 'Identification number'
        },
      },
      submit: 'Get the Agreement'
    },
    documentsView: {
      heading: 'Documents for viewing',
      pending: 'Fetching documents...'
    },
    sign: {
      heading: 'Sign the agreement with',
      smartId: {
        picture: [ smartId2x, smartId ],
        label: 'Smart-ID'
      },
      submit: 'Sign on the Spot'
    }
  },
  successPage: {
    picture: {
      src: successPicture,
      alt: 'People shake hands'
    },
    heading: 'Thank you!',
    description: `
      Your agreement has been signed. The merchant will contact you for the delivery time and terms. Please follow your payments with the bank and keep saving money with Finamba!
      <br />
      Be Finamba!
    `
  },
  rejectedPage: {
    picture: {
      src: rejectedPicture,
      alt: 'The man is worried'
    },
    heading: 'Rejected!',
    description: `
      We are very sorry, our partners have not approved your ability to split payments.
      Do not worry, please try again us later!
    `
  },
  notFound: {
    picture: {
      src: notFoundPicture,
      alt: 'Man think'
    },
    heading: 'Page not found',
    description: 'This page does not exists'
  },
  errors: {
    modal: {
      label: 'Error',
      close: 'Close'
    },
    [ERROR.NOT_FOUND]: {
      code: ERROR.NOT_FOUND,
      name: 'Merchant not found',
      description: 'Try again with correct merchant id'
    },
    [ERROR.AUTH_ERROR]: {
      code: ERROR.AUTH_ERROR,
      name: 'Authentication error',
      description: 'Incorrect personal ID'
    },
    [ERROR.NETWORK]: {
      code: ERROR.NETWORK,
      name: 'Network error',
      description: 'Server is not available. Check your internet connection and try again'
    },
    [ERROR.SESSION_EXPIRED]: {
      code: ERROR.SESSION_EXPIRED,
      name: 'Session expired',
      description: 'Please sign in again to continue'
    },
    [ERROR.EMAIL_EXISTS]: {
      code: ERROR.EMAIL_EXISTS,
      name: 'Email already in use',
      description: 'Please sign in with different email'
    },
    [ERROR.EMAIL_INVALID]: {
      code: ERROR.EMAIL_INVALID,
      name: 'Incorrect email address',
      description: 'Please enter a valid email address'
    },
    [ERROR.MISSING_REQUIRED_PARAM]: (param) => ({
      code: ERROR.MISSING_REQUIRED_PARAM,
      name: `${ param } is required`,
      description: `Please enter ${ param } and try again`
    }),
  },
  terms: {
    heading: 'Some Header Title Here',
    text: `
      <p>
        Finamba apstrādās Jūsu personas datus ar mērķi informēt Jūs par Finamba un tā sadarbības partneru aktuālajiem mārketinga piedāvājumiem, t.sk., bet ne tikai, akcijām, konkursiem, loterijām, aptaujām, jaunumiem un citām mārketinga aktivitātēm.
      </p>
      <p>
        Ar piekrišanas sniegšanu, apliecinājāt, ka savus personas datus šim nolūkam sniedzat brīvi un apzināti, ka šāds pienākums nav noteikts ar likumu, līgumu vai citā veidā. Gadījumā, ja nepiekritīsiet sniegt savus personas datus šim nolūkam vai vēlēsieties atsaukt savu piekrišanu, tas neietekmēs Jūsu tiesības saņemt standarta piedāvājumus un pakalpojumus, kādus attiecīgajā brīdī piedāvā Finamba vai kāds no Finamba platformas partneriem. Personas datu apstrādes juridiskais pamats ir Vispārīgas datu aizsardzības regulas 6.panta pirmās daļas a) apakšpunkts (Jūsu piekrišana)
      </p>

      <p>
        Piekrišanu saņemt Finamba un/vai tā sadarbības partneru mārketinga piedāvājumus var atsaukt jebkurā laikā, nosūtot pieteikumu uz e-pastu: info@finamba.com.
      </p>
    `,
    decline: 'Decline',
    accept: 'Accept'
  }
};

export const {
  meta,
  header,
  onboarding,
  startPage,
  auth,
  verificationPage,
  additionalInfo,
  initialPayment,
  backLink,
  offersPage,
  contractSigning,
  successPage,
  rejectedPage,
  notFound,
  errors,
  terms
} = data;

export default data;
