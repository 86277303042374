import { useDispatch } from 'react-redux';
import WebfontLoader from '@dr-kobros/react-webfont-loader';
import PropTypes from 'prop-types';

import { setAppLoaded } from '~store/reducers/app';

const webFontLoaderConfig = {
  custom: {
    families: [ 'Avenir Next' ],
  }
};

function FontLoader({ children }) {
  const dispatch = useDispatch();

  const fontsLoadedCallback = () => {
    dispatch(setAppLoaded());
  };

  return (
    <WebfontLoader config={ webFontLoaderConfig } onStatus={ fontsLoadedCallback }>
      { children }
    </WebfontLoader>
  );
}

FontLoader.propTypes = {
  children: PropTypes.node.isRequired
};

export default FontLoader;
