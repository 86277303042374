import { useField } from 'formik';

import Select from './Select';

function SelectField(props) {
  const [ field, meta, helpers ] = useField(props);

  const handleChange = (value) => helpers.setValue(value);

  return (
    <Select
      { ...field }
      { ...props }
      onChange={ handleChange }
      error={ meta.touched && meta.error }
    />
  );
}

export default SelectField;
