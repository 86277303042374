import classnames from 'classnames/bind';
import PropTypes from 'prop-types';

import * as styles from './Typography.module.scss';

const cn = classnames.bind(styles);

function Paragraph({
  size,
  marginTop,
  marginBottom,
  align,
  className,
  html,
  children
}) {
  const classes = cn(
    'paragraph',
    `paragraph--${ size }`,
    marginTop && `mt--${ marginTop }`,
    marginBottom && `mb--${ marginBottom }`,
    align && `align--${ align }`,
    className
  );

  return (
    <p
      className={ classes }
      dangerouslySetInnerHTML={ html ? { __html: html } : undefined }
    >
      { children }
    </p>
  );
}

Paragraph.propTypes = {
  size: PropTypes.oneOf([ 'small', 'large', 'default' ]),
  marginTop: PropTypes.oneOf([ null, '1', '2', '3', '4', 'auto' ]),
  marginBottom: PropTypes.oneOf([ null, '1', '2', '3', '4', 'auto' ]),
  align: PropTypes.oneOf([ null, 'left', 'right', 'center' ]),
  className: PropTypes.string,
  html: PropTypes.string,
  children: PropTypes.node,
};

Paragraph.defaultProps = {
  size: 'default',
  marginTop: null,
  marginBottom: null,
  align: null,
  className: '',
  html: undefined,
  children: undefined
};

export default Paragraph;
