import { Link, Route, Switch as RouterSwitch, useHistory, useLocation } from 'react-router-dom';
import classnames from 'classnames/bind';

import Icon from '~components/Icon';
import Logo from '~components/Logo';

// import { header } from '~data/en';
// import Switch from '~components/Switch';
import * as styles from './Header.module.scss';

const cn = classnames.bind(styles);

const contractPaths = {
  '/contract/verification': '/auth/signin',
  '/contract/additional-info': '/contract/verification',
  '/contract/initial-payment': '/contract/additional-info',
  '/contract/status': '/contract/initial-payment',
  '/contract/offers': '/contract/initial-payment',
  '/contract/sign': '/contract/offers',
};

function Header() {
  const location = useLocation();
  const history = useHistory();
  // const isSignInPath = useRouteMatch('/auth/signin');
  // const switchState = useRef(Number(Boolean(isSignInPath)));

  // const authLabels = header.authPaths.map((option) => option.label);

  // const handleAuthChange = (index) => {
  //   switchState.current = index;
  //   history.replace(authPaths[index].path);
  // };

  const handleBack = (e) => {
    e.preventDefault();

    history.push(contractPaths[location.pathname]);
  };

  return (
    <RouterSwitch>
      {/* <Route path="/auth">
        <header className={ cn('header', 'header--auth') }>
          <Switch
            value={ switchState.current }
            options={ authLabels }
            onChange={ handleAuthChange }
          />
        </header>
      </Route> */}
      <Route path="/contract">
        <header className={ cn('header', 'header--contract') }>
          <Link to="/" onClick={ handleBack } className={ styles.back }>
            <Icon icon="arrow-left" />
          </Link>

          <Logo to="/" />
        </header>
      </Route>
      <Route path="*">
        <header className={ styles.header }>
          <Logo to="/" />
        </header>
      </Route>
    </RouterSwitch>
  );
}

export default Header;
