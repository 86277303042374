import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// import Button from '~components/Button';
import ErrorModal from '~components/ErrorModal';
import { useLoader } from '~components/Loader';
import { Heading, Paragraph } from '~components/Typography';
import VerificationModal from '~components/VerificationModal';

import { getAuthStatus, signInCustomer } from '~store/actions/customer';
import { clearCustomerErrors,
  clearVerificationCode,
  customerSelector } from '~store/reducers/customer';
import useScrollRestoration from '~shared/helpers/hooks/useScrollRestoration';

import { verificationPage } from '~data/en';

import * as styles from './Verification.module.scss';

function Verification() {
  useScrollRestoration();

  const { setLoading } = useLoader();
  const history = useHistory();

  const dispatch = useDispatch();
  const { error, verificationCode } = useSelector(customerSelector);

  const handleVerification = (providerId) => () => {
    if (providerId !== 'smartid') return;

    setLoading(true);

    dispatch(signInCustomer())
      .finally(() => {
        setLoading(false);
      });
  };

  const handleVerificationStart = () => dispatch(getAuthStatus()).then((res) => {
    if (res.payload.status === 1) return Promise.resolve(-1);
    if (res.payload.status !== 6) return Promise.reject(res.payload.error);

    return Promise.resolve(1);
  });
  const handleVerificationComplete = () => history.push('/contract/additional-info');
  const handleVerificationEnd = () => dispatch(clearVerificationCode());
  const handleErrorModalClose = () => dispatch(clearCustomerErrors());

  const renderProviders = verificationPage.providers.map(({ picture, label, id }) => (
    <li className={ styles.item } key={ id }>
      <button
        type="button"
        className={ styles.button }
        onClick={ handleVerification(id) }
      >
        <img
          src={ picture[1] }
          srcSet={ `${ picture[0] } 2x, ${ picture[1] }` }
          alt={ label }
          height="48"
        />
      </button>
    </li>
  ));

  return (
    <div className={ styles.verification }>
      <Heading
        type="h2"
        html={ verificationPage.heading }
      />
      <Paragraph
        marginTop="1"
        marginBottom="2"
        html={ verificationPage.description }
      />

      <ul className={ styles.providersList }>
        { renderProviders }
      </ul>

      <VerificationModal
        verificationCode={ verificationCode }
        onVerificationStart={ handleVerificationStart }
        onVerificationComplete={ handleVerificationComplete }
        onVerificationEnd={ handleVerificationEnd }
      />

      <ErrorModal
        error={ error }
        onClose={ handleErrorModalClose }
      />

      {/* <Button
        to="/contract/additional-info"
        disabled={ !provider }
        block
      >
        { verificationPage.submit }
      </Button> */}
    </div>
  );
}

export default Verification;
