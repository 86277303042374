import { applyMiddleware, createStore as reduxCreateStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import rootReducer from './reducers';

const middlewares = [ thunk ];

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
});

const createStore = () => {
  const store = reduxCreateStore(
    rootReducer,
    {},
    composeEnhancers(applyMiddleware(...middlewares)),
  );
  const persistor = persistStore(store);

  return {
    store,
    persistor,
  };
};

export default createStore;
