import { useRef, useState } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';

import Icon from '~components/Icon';
import Modal from '~components/Modal';

import * as styles from './Select.module.scss';

const cn = classnames.bind(styles);

function Select({
  name,
  placeholder,
  value,
  options,
  renderOption,
  renderSelectedOption,
  error,
  className,
  onChange
}) {
  const ModalRef = useRef();
  const [ focused, setFocused ] = useState(false);
  const [ selectedOption, setSelectedOption ] = useState(value);

  const controlClasses = cn(
    'control',
    error && 'control--error',
    focused && 'control--focused',
    selectedOption && 'control--filled',
    className,
  );

  const handleClick = () => {
    ModalRef.current.openModal();
  };

  const handleFocus = () => {
    if (focused) return;

    ModalRef.current.openModal();
    setFocused(true);
  };

  const handleSelect = (option) => () => {
    setSelectedOption(option);
    onChange(option);
    ModalRef.current.closeModal();
  };

  const handleBlur = () => {
    setFocused(ModalRef.current.isOpen);
  };

  const renderOptions = options.map((option) => (
    <li
      key={ option.value }
      className={ styles.item }
    >
      {
        typeof renderOption === 'function'
          ? renderOption(option, handleSelect(option))
          : (

            <button
              type="button"
              className={ styles.option }
              onClick={ handleSelect(option) }
            >
              { option.label }
            </button>
          )
      }
    </li>
  ));

  const renderBox = () => {
    if (!selectedOption) {
      return (
        <span className={ styles.placeholder }>
          { placeholder }
        </span>
      );
    }

    if (typeof renderSelectedOption === 'function') {
      return renderSelectedOption(selectedOption, placeholder);
    }

    return (
      <span
        className={ styles.box }
      >
        { selectedOption.label }
      </span>
    );
  };

  return (
    <>
      <label className={ controlClasses }>
        <input
          name={ name }
          placeholder={ placeholder }
          className="visually-hidden"
          onClick={ handleClick }
          onFocus={ handleFocus }
          onBlur={ handleBlur }
          readOnly
          value={ selectedOption ? selectedOption.label : '' }
        />

        { renderBox() }

        <Icon className={ styles.arrow } icon="caret-bottom" />
      </label>

      <Modal
        transition="slide-bottom"
        ref={ ModalRef }
        label={ `${ name } Options` }
      >
        <ul className={ styles.options }>
          { renderOptions }
        </ul>
      </Modal>
    </>
  );
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
      label: PropTypes.string.isRequired,
      img: PropTypes.arrayOf(PropTypes.string)
    }),
    PropTypes.oneOf([ null ])
  ]),
  options: PropTypes
    .arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
      label: PropTypes.string.isRequired,
      img: PropTypes.arrayOf(PropTypes.string)
    }))
    .isRequired,
  renderOption: PropTypes.oneOfType([ PropTypes.func, PropTypes.oneOf([ null ]) ]),
  renderSelectedOption: PropTypes.oneOfType([ PropTypes.func, PropTypes.oneOf([ null ]) ]),
  error: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string
};

Select.defaultProps = {
  value: null,
  renderOption: null,
  renderSelectedOption: null,
  placeholder: '',
  error: false,
  className: ''
};

export default Select;
