import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';

import Icon from '~components/Icon';

import * as styles from './Input.module.scss';

const cn = classnames.bind(styles);

const Input = React.forwardRef(({
  align,
  large,
  startAdornment,
  endAdornment,
  error,
  outline,
  value,
  className,
  onBlur,
  onFocus,
  ...restProps
}, ref) => {
  const inputRef = useRef();
  const [ focused, setFocused ] = useState(false);

  const controlClasses = cn(
    'control',
    large && 'control--large',
    focused && 'control--focused',
    value && 'control--filled',
    outline && 'control--outline',
    error && 'control--error',
    className,
  );

  const inputClasses = cn(
    'input',
    align && `input--align-${ align }`,
  );

  const startAdornmentClasses = cn(
    'adornment--start',
    typeof startAdornment === 'string' && 'adornment--string'
  );

  const endAdornmentClasses = cn(
    'adornment--end',
    'adornment--string'
  );

  const handleFocus = (e) => {
    setFocused(true);

    onFocus(e);
  };

  const handleBlur = (e) => {
    setFocused(false);

    onBlur(e);
  };

  useEffect(() => {
    inputRef.current.value = value;
  }, []);

  useImperativeHandle(ref, () => inputRef.current);

  return (
    <label className={ controlClasses }>
      {
        startAdornment && (
          <span className={ startAdornmentClasses }>
            { startAdornment }
          </span>
        )
      }
      <input
        ref={ inputRef }
        className={ inputClasses }
        { ...restProps }
        onFocus={ handleFocus }
        onBlur={ handleBlur }
      />
      {
        endAdornment && (
          <span className={ endAdornmentClasses }>
            { endAdornment }
          </span>
        )
      }
      {
        !endAdornment && error && (
          <span className={ styles.error }>
            <Icon icon="cross" />
          </span>
        )
      }
    </label>
  );
});

Input.propTypes = {
  align: PropTypes.oneOf([ 'left', 'right' ]),
  large: PropTypes.bool,
  value: PropTypes.string,
  error: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
  outline: PropTypes.bool,
  startAdornment: PropTypes.oneOfType([ PropTypes.node, PropTypes.string ]),
  endAdornment: PropTypes.oneOfType([ PropTypes.node, PropTypes.string ]),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string
};

Input.defaultProps = {
  align: 'left',
  error: false,
  value: '',
  large: false,
  outline: false,
  startAdornment: null,
  endAdornment: null,
  onFocus: () => {},
  onBlur: () => {},
  className: ''
};

export default Input;
