import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';

import Icon from '~components/Icon';

import styles from './Button.module.scss';

const cn = classnames.bind(styles);

const Button = ({
  to,
  href,
  type,
  variant,
  icon,
  block,
  target,
  disabled,
  onClick,
  children,
  className,
  ...restProps
}) => {
  const buttonClassName = cn(
    'button',
    `button--${ variant }`,
    disabled && 'button--disabled',
    block && 'button--block',
    className
  );

  let ButtonComponent = null;

  switch (true) {
    case to !== null:
      ButtonComponent = Link;

      break;
    case typeof href === 'string':
      ButtonComponent = 'a';

      break;
    default:
      ButtonComponent = 'button';
  }

  const renderedChildren = React.Children.map(children, (child) => {
    if (variant === 'secondary') {
      return (
        <>
          <span className={ styles.icon }>
            <Icon icon={ icon } />
          </span>
          <span className={ styles.label }>{child}</span>
        </>
      );
    }

    return child;
  });

  return (
    <ButtonComponent
      to={ !disabled ? to : '' }
      href={ !disabled ? href : '' }
      type={ type }
      target={ target }
      className={ buttonClassName }
      disabled={ disabled }
      onClick={ !disabled ? onClick : undefined }
      { ...restProps }
    >
      { renderedChildren }
    </ButtonComponent>
  );
};

Button.propTypes = {
  to: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
  href: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'third',
    'outline'
  ]),
  icon(props, propName) {
    if (props.variant === 'secondary' && (props[propName] === undefined)) {
      return new Error('Icon property is required for this variant of Button!');
    }

    if (props.variant === 'secondary' && (props[propName] !== undefined) && (typeof props[propName] !== 'string')) {
      return new Error('Icon property must be a string!');
    }
  },
  block: PropTypes.bool,
  disabled: PropTypes.bool,
  target: PropTypes.oneOf([ '_blank', '_top', '_parent' ]),
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
};

Button.defaultProps = {
  to: null,
  href: null,
  type: 'button',
  variant: 'primary',
  icon: null,
  block: false,
  target: undefined,
  disabled: false,
  children: null,
  className: '',
  onClick: () => {},
};

export default Button;
