import { useDispatch, useSelector } from 'react-redux';

import Button from '~components/Button';
import { Heading } from '~components/Typography';

import { requestSelector, setSelectedOffer } from '~store/reducers/request';
import useScrollRestoration from '~shared/helpers/hooks/useScrollRestoration';

import { offersPage } from '~data/en';

import * as styles from './SplitPayment.module.scss';

function SplitPayment() {
  useScrollRestoration();

  const dispatch = useDispatch();
  const { selectedOffer, offers } = useSelector(requestSelector);

  const handleVariantChange = (e) => {
    dispatch(setSelectedOffer(e.target.value));
  };

  const renderVariants = offers.map(({
    id, productType, productDays, paymentAmount, withInterest
  }) => {
    const productTypeLabel = productType.charAt(0).toUpperCase() + productType.slice(1);
    const paymentTime = productDays > 30
      ? `${ productDays / 30 } ${ offersPage.offer.time.months }`
      : `${ productDays } ${ offersPage.offer.time.days }`;

    return (
      <li key={ id } className={ styles.item }>
        <label className={ styles.variant }>
          <input
            type="radio"
            name="split-payment"
            value={ id }
            className={ styles.control }
            onChange={ handleVariantChange }
          />
          <div
            className={ styles.label }
          >
            <span className={ styles.type }>
              { productTypeLabel }
              :
            </span>
            <span className={ styles.time }>
              { paymentTime }
            </span>
            <span className={ styles.payment }>
              <span
                dangerouslySetInnerHTML={ { __html: offersPage.offer.amount } }
              />
              <strong>
                <span
                  dangerouslySetInnerHTML={ { __html: offersPage.offer.currency } }
                />
                { paymentAmount }
              </strong>
            </span>
            {
              withInterest && (
                <span className={ styles.interest }>{ offersPage.offer.withInterest }</span>
              )
            }
          </div>
        </label>
      </li>
    );
  });

  return (
    <div className={ styles.container }>
      <Heading type="h2" html={ offersPage.heading } />

      <ul className={ styles.variantsList }>
        { renderVariants }
      </ul>

      <Button
        to="/contract/sign"
        disabled={ !selectedOffer }
        block
      >
        { offersPage.submit }
      </Button>
    </div>
  );
}

export default SplitPayment;
