import PropTypes from 'prop-types';

import Icon from '~components/Icon';

import styles from './Spinner.module.scss';

const Spinner = ({ label }) => (
  <div className={ styles.spinner }>
    <Icon icon="spinner" className={ styles.icon } />
    {
      label && (
        <span className={ styles.label }>{ label }</span>
      )
    }
  </div>
);

Spinner.propTypes = {
  label: PropTypes.string
};

Spinner.defaultProps = {
  label: ''
};

export default Spinner;
