import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';

import Option from './Option';

import * as styles from './Switch.module.scss';

const cn = classnames.bind(styles);

function Switch({ name, value, onChange, options, classNames, optionComponent }) {
  const switchName = useRef(name);
  const [ selectedOption, setSelectedOption ] = useState(value);

  const classes = {
    switch: cn('switch', classNames.switch),
    indicator: cn('indicator', classNames.indicator),
    container: cn('container', classNames.container),
    option: cn('option', classNames.option),
    optionSelected: cn('option--selected', classNames.optionSelected)
  };

  const optionsCount = options.length;
  const optionWidth = `${ 100 / optionsCount }%`;
  const indicatorPosition = `translateX(${ 100 * selectedOption }%)`;

  const renderOptions = options.map((option, index) => (optionComponent
    ? React.cloneElement(optionComponent, {
      option,
      index,
      name: switchName.current,
      onChange: () => setSelectedOption(index),
    })
    : (
      <Option
        key={ option }
        className={ cn(classes.option, selectedOption === index && classes.optionSelected) }
        option={ option }
        name={ switchName.current }
        onChange={ () => setSelectedOption(index) }
      />
    )
  ));

  useEffect(() => {
    onChange(selectedOption);
  }, [ selectedOption ]);

  return (
    <div className={ classes.switch }>
      <div
        className={ classes.indicator }
        style={ { width: optionWidth, transform: indicatorPosition } }
      />

      <div className={ classes.container } style={ { gridAutoColumns: optionWidth } }>
        { renderOptions }
      </div>
    </div>
  );
}

Switch.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.string),
  classNames: PropTypes.shape({
    switch: PropTypes.string,
    indicator: PropTypes.string,
    container: PropTypes.string,
    option: PropTypes.string,
    optionSelected: PropTypes.string,
  }),
  optionComponent: PropTypes.node
};

Switch.defaultProps = {
  name: uniqueId('switch-'),
  value: 0,
  options: [],
  onChange: () => {},
  classNames: {
    switch: '',
    indicator: '',
    container: '',
    option: '',
    optionSelected: '',
  },
  optionComponent: null,
};

export default Switch;
