import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { header } from '~data/en';

import logo from './logo.svg';

import * as styles from './Logo.module.scss';

function Logo({ to }) {
  if (to) {
    return (
      <Link className={ styles.logo } to={ to }>
        <img src={ logo } width="90" height="20" alt={ header.logoAlt } />
      </Link>
    );
  }

  return (
    <div className={ styles.logo }>
      <img src={ logo } width="90" height="20" alt={ header.logoAlt } />
    </div>
  );
}

Logo.propTypes = {
  to: PropTypes.string
};

Logo.defaultProps = {
  to: null
};

export default Logo;
