import { useField } from 'formik';
import PropTypes from 'prop-types';

import Input from './Input';
import MaskedInput from './MaskedInput';

function InputField({ mask, ...props }) {
  const [ field, meta, helpers ] = useField(props);

  if (mask) {
    return (
      <MaskedInput
        mask={ mask }
        { ...field }
        { ...props }
        onChange={ (v) => helpers.setValue(v) }
        error={ meta.touched && meta.error }
      />
    );
  }

  return (
    <Input
      { ...field }
      { ...props }
      error={ meta.touched && meta.error }
    />
  );
}

InputField.propTypes = {
  mask: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
};

InputField.defaultProps = {
  mask: null,
};

export default InputField;
