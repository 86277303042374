import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import appReducer from './app';
import customerReducer from './customer';
import merchantReducer from './merchant';
import paymentReducer from './request';

const createPersistConfig = (key, { blacklist }) => ({
  key,
  storage,
  blacklist
});

const rootReducer = combineReducers({
  app: appReducer,
  merchant: merchantReducer,
  customer: customerReducer,
  request: paymentReducer
});

export default persistReducer(createPersistConfig('root', { blacklist: 'app' }), rootReducer);
