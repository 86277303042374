import { useState } from 'react';

import Loader from './Loader';
import { LoaderContext } from './loaderContext';

function withLoader(WrappedComponent) {
  function WithLoader(props) {
    const [ isLoading, setLoading ] = useState(false);

    const contextValues = {
      isLoading,
      setLoading,
    };

    return (
      <LoaderContext.Provider value={ contextValues }>
        { isLoading && <Loader /> }
        <WrappedComponent { ...props } />
      </LoaderContext.Provider>
    );
  }

  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  WithLoader.displayName = `withLoader(${ wrappedComponentName })`;

  return WithLoader;
}

export default withLoader;
