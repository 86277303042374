import { createSlice } from '@reduxjs/toolkit';

import { ERROR } from '~shared/constants';

import { getMerchantInfo } from '../actions/request';

export const merchantSlice = createSlice({
  name: 'merchant',
  initialState: {
    loading: false,
    id: '',
    logo: '',
    name: ''
  },
  reducers: {
    clearMerchantErrors(state) {
      return {
        ...state,
        error: null
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getMerchantInfo.pending, (state, action) => {
      if (state.loading) return;

      return {
        ...state,
        id: action.meta.arg,
        loading: true
      };
    });
    builder.addCase(getMerchantInfo.fulfilled, (state, { payload }) => {
      if (!state.loading) return;

      return {
        ...state,
        error: null,
        ...payload,
        loading: false
      };
    });
    builder.addCase(getMerchantInfo.rejected, (state, { payload }) => {
      if (payload.error.code) {
        return {
          error: payload.error,
          loading: false,
        };
      }

      return {
        error: {
          code: ERROR.UNKNOWN,
          name: 'Something went wrong',
          description: 'We already fixing the problem. Please try again later'
        },
        loading: false
      };
    });
  }
});

export const {
  clearMerchantErrors
} = merchantSlice.actions;

export const merchantSelector = (state) => state.merchant;
export const merchantIdSelector = (state) => state.merchant.id;

export default merchantSlice.reducer;
