import * as Yup from 'yup';

const InfoSchema = Yup.object().shape({
  city: Yup.string().required('Required'),
  street: Yup.string().required('Required'),
  zip: Yup.string().required('Required'),
  monthlyIncome: Yup.number().min(0).required('Required'),
  monthlyExpenses: Yup.number().min(0).required('Required'),
  occupation: Yup.object().shape({
    value: Yup.number(),
    label: Yup.string()
  }).required('Required'),
  coreBank: Yup.object().shape({
    value: Yup.number(),
    label: Yup.string()
  }).required('Required'),
  pep: Yup.bool(),
  consent: Yup.bool().oneOf([ true ], 'Field must be checked')
});

export default InfoSchema;
