import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Icon from '~components/Icon';
import Input from '~components/Input';
import { Heading } from '~components/Typography';

import { getRequestStatus } from '~store/actions/request';
import { updateCustomer } from '~store/reducers/customer';
import { updateRequestId } from '~store/reducers/request';
import useClipboard from '~shared/helpers/hooks/useClipboard';
import useQuery from '~shared/helpers/hooks/useQuery';
import useScrollRestoration from '~shared/helpers/hooks/useScrollRestoration';

import { backLink } from '~data/en';

import * as styles from './BackLink.module.scss';

const BackLink = () => {
  const backLinkRef = useRef(window.location.href);
  const copiedTimeout = useRef(0);
  const requestTimeout = useRef(0);
  const [ copied, setCopied ] = useState(false);

  useScrollRestoration();

  const dispatch = useDispatch();

  const query = useQuery();
  const { copyToClipboard } = useClipboard();
  const history = useHistory();

  const handleCopy = () => copyToClipboard(backLinkRef.current).then(() => {
    clearTimeout(copiedTimeout.current);

    setCopied(true);

    copiedTimeout.current = setTimeout(() => {
      setCopied(false);
    }, 3000);
  });

  useEffect(() => {
    const requestId = query.get('requestId');
    const customerId = query.get('customerId');

    if (!requestId || !customerId) {
      history.replace('/');
    }

    dispatch(updateRequestId(requestId));

    dispatch(updateCustomer({
      id: customerId
    }));
  }, []);

  useEffect(() => {
    requestTimeout.current = setInterval(() => {
      dispatch(getRequestStatus())
        .then((res) => {
          if (res.payload.status === 'approved' && res.payload.offers.length > 0) {
            history.push('/contract/offers');
          }
        });
    }, 1000);

    return () => {
      clearTimeout(copiedTimeout.current);
      clearInterval(requestTimeout.current);
    };
  }, []);

  return (
    <div className={ styles.container }>
      <img
        className={ styles.img }
        src={ backLink.picture.src }
        alt={ backLink.picture.alt }
        width="247"
        height="247"
      />

      <Heading
        type="p"
        size="h2"
        marginTop="3"
        className={ styles.description }
        html={ backLink.description }
      />

      <div className={ styles.input }>
        <Input
          value={ backLinkRef.current }
          startAdornment={
            (
              <Icon
                className={ styles.linkIcon }
                variant="accent"
                icon="link"
              />
            )
          }
          endAdornment={
            (
              <button
                type="button"
                className={ styles.copyButton }
                onClick={ handleCopy }
              >
                <Icon icon={ copied ? 'check' : 'copy' } />
              </button>
            )
          }
          readOnly
        />
      </div>
    </div>
  );
};

export default BackLink;
