import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Button from '~components/Button';
import Modal from '~components/Modal';
import { Heading } from '~components/Typography';

import styles from './VerificationModal.module.scss';

const VerificationModal = ({
  verificationCode,
  onVerificationStart,
  onVerificationComplete,
  onVerificationError,
  onVerificationEnd,
  canClose
}) => {
  const modalRef = useRef();
  const verificationInterval = useRef(0);

  const handleCloseButtonClick = () => {
    modalRef.current.closeModal();
  };

  const handleModalClose = () => {
    clearInterval(verificationInterval.current);
  };

  useEffect(() => {
    if (!modalRef.current) return;

    if (verificationCode && !modalRef.current.isOpen) {
      modalRef.current.openModal();

      if (typeof onVerificationStart !== 'function') return;

      verificationInterval.current = setInterval(() => {
        onVerificationStart()
          .then((status) => {
            if (status === -1) return;

            clearInterval(verificationInterval.current);

            if (status === 1) {
              onVerificationComplete();

              onVerificationEnd();

              return;
            }

            if (status === 0) {
              onVerificationError();

              onVerificationEnd();
            }
          })
          .catch((e) => {
            clearInterval(verificationInterval.current);

            onVerificationError(e);
            onVerificationEnd();
          });
      }, 1000);
    }
  }, [ verificationCode ]);

  useEffect(() => () => {
    clearInterval(verificationInterval.current);
  }, []);

  return (
    <Modal ref={ modalRef } label="Verification code" onClose={ handleModalClose }>
      <article className={ styles.codeModal }>
        <header className={ styles.header }>
          <Heading type="h2">
            Verification code
          </Heading>
        </header>

        <div className={ styles.content }>
          <code>{ verificationCode }</code>
        </div>

        {
          canClose && (
            <footer className={ styles.footer }>
              <Button onClick={ handleCloseButtonClick } block>Ok</Button>
            </footer>
          )
        }
      </article>
    </Modal>
  );
};

VerificationModal.propTypes = {
  verificationCode: PropTypes.string,
  onVerificationStart: PropTypes.func,
  onVerificationComplete: PropTypes.func,
  onVerificationError: PropTypes.func,
  onVerificationEnd: PropTypes.func,
  canClose: PropTypes.bool
};

VerificationModal.defaultProps = {
  verificationCode: null,
  onVerificationStart: null,
  onVerificationComplete: () => {},
  onVerificationError: () => {},
  onVerificationEnd: () => {},
  canClose: false
};

export default VerificationModal;
