import { Heading, Paragraph } from '~components/Typography';

import useScrollRestoration from '~shared/helpers/hooks/useScrollRestoration';

import { rejectedPage } from '~data/en';

import * as styles from './Result.module.scss';

function Rejected() {
  useScrollRestoration();

  return (
    <div className={ styles.result }>
      <div className={ styles.screen }>
        <img
          className={ styles.illustration }
          src={ rejectedPage.picture.src }
          alt={ rejectedPage.picture.alt }
          width="247"
          height="247"
        />

        <div className={ styles.content }>
          <Heading
            type="h1"
            marginTop="2"
            html={ rejectedPage.heading }
          />
          <Paragraph
            marginTop="1"
            html={ rejectedPage.description }
          />
        </div>
      </div>
    </div>
  );
}

export default Rejected;
