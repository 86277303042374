import { ERROR } from '~shared/constants';
import { errors } from '~data/en';

const initRequestApi = (instance) => ({
  createRequest: async ({ merchant, customer, request }) => {
    try {
      const res = await instance.post('/requests/request/v1/', {
        merchant,
        customer: customer.id,
        order_id: customer.email,
        initial_payment: request.initialPayment,
        items: [{ price: request.creditAmount, name: request.product }]
      });

      return res.data;
    } catch (e) {
      return {
        error: errors[ERROR.NETWORK]
      };
    }
  },
  getRequestStatus: async ({ requestId, customerId }) => {
    try {
      const res = await instance.post(`/requests/request/v1/${ requestId }/status/`, {
        customer: customerId
      });

      let modifiedResponse = res.data;

      if (res.data.status === 'approved') {
        modifiedResponse = {
          ...res.data,
          offers: res.data.offers.map(({
            // eslint-disable-next-line camelcase
            payment_amount, product_type, product_days, ...restProps
          }) => ({
            paymentAmount: payment_amount,
            productType: product_type,
            productDays: product_days,
            ...restProps
          }))
        };
      }

      return modifiedResponse;
    } catch (e) {
      return {
        error: errors[ERROR.NETWORK]
      };
    }
  },
  generateRequestDocuments: async ({
    documentType, documentNumber, requestId, customerId, offerId
  }) => {
    try {
      const res = await instance.post(`/requests/request/v1/${ requestId }/generate_documents/`, {
        customer: customerId,
        offer: offerId,
        document_type: documentType.value,
        document_number: documentNumber
      });

      return res.data;
    } catch (e) {
      return {
        error: errors[ERROR.NETWORK]
      };
    }
  },
  viewRequestDocuments: async ({ requestId, customerId, offerId }) => {
    try {
      const res = await instance.post(`/requests/request/v1/${ requestId }/view_documents/`, {
        customer: customerId,
        offer: offerId
      });

      return res.data;
    } catch (e) {
      return {
        error: errors[ERROR.NETWORK]
      };
    }
  },
  signContract: async ({ requestId, customerId, offerId }) => {
    try {
      const res = await instance.post(`/requests/request/v1/${ requestId }/sign/`, {
        customer: customerId,
        offer: offerId,
      });

      return {
        signSessionId: res.data.session_id,
        verificationCode: res.data.verification_code
      };
    } catch (e) {
      return {
        error: errors[ERROR.NETWORK]
      };
    }
  },
  getContractSignStatus: async ({ signSessionId, requestId, customerId, offerId }) => {
    if (!signSessionId) return;

    try {
      const res = await instance.post(`/requests/request/v1/${ requestId }/sign_status/`, {
        customer: customerId,
        offer: offerId,
        sign_session: signSessionId
      });

      return res.data;
    } catch (e) {
      return {
        error: errors[ERROR.NETWORK]
      };
    }
  }
});

export default initRequestApi;
