import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '~components/Button';
import Icon from '~components/Icon';
import Input from '~components/Input';
import { useLoader } from '~components/Loader';
import Switch from '~components/Switch';
import { Heading } from '~components/Typography';

import { createRequest } from '~store/actions/request';
import { customerSelector } from '~store/reducers/customer';
import { requestSelector, setInitialPayment } from '~store/reducers/request';
import useScrollRestoration from '~shared/helpers/hooks/useScrollRestoration';

import data from '~data/en';

import * as styles from './InitialPayment.module.scss';

function SplitPayment() {
  const [ dropdownOpen, setDropdownOpen ] = useState(false);
  const [ splitType, setSplitType ] = useState(0);
  const [ splitPercentage, setSplitPercentage ] = useState('');
  const [ splitAmount, setSplitAmount ] = useState('');

  useScrollRestoration();

  const history = useHistory();
  const { setLoading } = useLoader();

  const dispatch = useDispatch();
  const { creditAmount, initialPayment } = useSelector(requestSelector);
  const customer = useSelector(customerSelector);

  const handleChange = (value) => {
    dispatch(setInitialPayment(value));
  };

  const handleSliderChange = (index) => {
    const multiplier = parseInt(data.initialPayment.percentageOptions[index], 10) / 100;

    handleChange(multiplier * creditAmount);
  };

  const handleInputChange = (e) => {
    // split by amount
    if (splitType) {
      setSplitAmount(e.target.value);
      handleChange(e.target.value);

      return;
    }

    // split by percentage
    const multiplier = e.target.value / 100;

    setSplitPercentage(e.target.value);
    handleChange(multiplier * creditAmount);
  };

  const handleSplit = () => {
    setLoading(true);

    dispatch(createRequest())
      .then(({ payload }) => {
        if (payload.id) {
          history.push(`/contract/status?requestId=${ payload.id }&customerId=${ customer.id }`);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  useEffect(() => {
    if (splitType && splitAmount) {
      setSplitPercentage('');
    }

    if (!splitType && splitPercentage) {
      setSplitAmount('');
    }
  }, [ splitType, splitAmount, splitPercentage ]);

  return (
    <div className={ styles.container }>
      <div className={ styles.totalAmount }>
        <span
          dangerouslySetInnerHTML={ { __html: data.initialPayment.totalAmount } }
        />

        <span className={ styles.totalAmountValue }>
          { data.initialPayment.currency }
          {' '}
          { creditAmount - initialPayment }
        </span>
      </div>

      <Heading
        type="h2"
        marginTop="3"
        marginBottom="2"
        html={ data.initialPayment.heading }
      />

      <Switch
        options={ data.initialPayment.percentageOptions }
        onChange={ handleSliderChange }
      />

      <p className={ styles.payment }>
        <span
          dangerouslySetInnerHTML={ { __html: data.initialPayment.label } }
        />
        <span className={ styles.paymentSum }>
          <span
            dangerouslySetInnerHTML={ { __html: data.initialPayment.currency } }
          />
          {' '}
          { initialPayment }
        </span>
      </p>

      <div className={ styles.customSum }>
        <div className={ styles.headline } onClick={ toggleDropdown }>
          <Heading type="h3" html={ data.initialPayment.heading2 } />
          <Icon className={ styles.arrow } icon="chevron-down" />
        </div>

        {
          dropdownOpen && (
            <div className={ styles.content }>
              <div className={ styles.switch }>
                <Switch options={ data.initialPayment.splitOptions } onChange={ setSplitType } />
              </div>

              <div className={ styles.control }>
                {
                  splitType ? (
                    <Input
                      key="amount"
                      value={ splitAmount }
                      onChange={ handleInputChange }
                      startAdornment={ data.initialPayment.currency }
                      outline
                    />
                  ) : (
                    <Input
                      key="percentage"
                      value={ splitPercentage }
                      onChange={ handleInputChange }
                      startAdornment="%"
                      outline
                    />
                  )
                }
              </div>
            </div>
          )
        }
      </div>

      <div className={ styles.button }>
        <Button
          type="button"
          block
          onClick={ handleSplit }
        >
          { data.initialPayment.submit }
        </Button>
      </div>
    </div>
  );
}

export default SplitPayment;
