import { Heading, Paragraph } from '~components/Typography';

import useScrollRestoration from '~shared/helpers/hooks/useScrollRestoration';

import { notFound } from '~data/en';

import * as styles from './NotFoundPage.module.scss';

function NotFoundPage() {
  useScrollRestoration();

  return (
    <div className={ styles.page }>
      <div className={ styles.container }>
        <img
          className={ styles.illustration }
          src={ notFound.picture.src }
          alt={ notFound.picture.alt }
          width="247"
          height="247"
        />

        <div className={ styles.content }>

          <Heading
            type="h1"
            marginTop="2"
            html={ notFound.heading }
          />
          <Paragraph
            marginTop="1"
            html={ notFound.description }
          />
        </div>
      </div>
    </div>
  );
}

export default NotFoundPage;
