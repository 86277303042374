/* eslint-disable max-len */
import React, { useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';

import Button from '~components/Button';
import Modal from '~components/Modal';
import { Heading } from '~components/Typography';

import { terms } from '~data/en';

import * as styles from './TermsModal.module.scss';

const Terms = React.forwardRef(({ onAccept }, ref) => {
  const ModalRef = useRef();

  const handleAccept = () => {
    ModalRef.current.closeModal();
    onAccept();
  };

  const handleDecline = () => {
    ModalRef.current.closeModal();
  };

  useImperativeHandle(ref, () => ({
    openTerms: ModalRef.current.openModal
  }));

  return (
    <Modal ref={ ModalRef } label="Terms & conditions">
      <article className={ styles.terms }>
        <header className={ styles.header }>
          <Heading type="h2">
            { terms.heading }
          </Heading>
        </header>

        <div
          className={ styles.content }
          dangerouslySetInnerHTML={ { __html: terms.text } }
        />

        <footer className={ styles.footer }>
          <Button
            variant="third"
            className={ styles.button }
            onClick={ handleDecline }
          >
            { terms.decline }
          </Button>
          <Button
            className={ styles.button }
            onClick={ handleAccept }
          >
            { terms.accept }
          </Button>
        </footer>
      </article>
    </Modal>
  );
});

Terms.propTypes = {
  onAccept: PropTypes.func
};

Terms.defaultProps = {
  onAccept: () => {}
};

export default Terms;
