import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import NotFoundPage from '~pages/NotFoundPage';

import useQuery from '~shared/helpers/hooks/useQuery';

const NavigationGuard = ({ param, selector, children }) => {
  const params = useParams();
  const queryParams = useQuery();
  const protectedValue = useSelector(selector);

  switch (true) {
    case Boolean(params[param] || queryParams.get(param) || protectedValue):
      return children;
    default:
      return <NotFoundPage />;
  }
};

NavigationGuard.propTypes = {
  param: PropTypes.string,
  selector: PropTypes.func,
  children: PropTypes.node.isRequired
};

NavigationGuard.defaultProps = {
  param: '',
  selector: null
};

export default NavigationGuard;
