import { ERROR } from '~shared/constants';
import { errors } from '~data/en';

const initMerchantApi = (instance) => ({
  getMerchantInfo: async (id) => {
    if (!id) return;

    try {
      const res = await instance.get(`/merchants/v1/${ id }/`);

      return res.data;
    } catch (e) {
      if (e.response.status === 404) {
        return {
          error: errors[ERROR.NOT_FOUND]
        };
      }

      return {
        error: errors[ERROR.NETWORK]
      };
    }
  }
});

export default initMerchantApi;
