import { createSlice } from '@reduxjs/toolkit';

import { ERROR } from '~shared/constants';

import { createRequest,
  generateRequestDocuments,
  getContractSignStatus,
  getRequestStatus,
  signContract,
  viewRequestDocuments } from '../actions/request';

export const requestSlice = createSlice({
  name: 'request',
  initialState: {
    loading: 'idle',
    product: 'Service',
    requestId: '',
    creditAmount: '',
    initialPayment: '',
    selectedOffer: '',
    status: '',
    offers: [],
    documents: [],
  },
  reducers: {
    setCreditAmount(state, { payload }) {
      state.creditAmount = payload;
    },
    setInitialPayment(state, { payload }) {
      state.initialPayment = payload;
    },
    setSelectedOffer(state, { payload }) {
      state.selectedOffer = payload;
    },
    updateRequestId(state, { payload }) {
      state.requestId = payload;
    },
    clearPaymentErrors(state) {
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createRequest.pending, (state) => {
      if (state.loading) return state;

      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(createRequest.fulfilled, (state, { payload }) => {
      if (!state.loading) return state;

      return {
        ...state,
        requestId: payload.id,
        error: null,
        loading: false,
      };
    });
    builder.addCase(createRequest.rejected, (state, { payload }) => {
      if (payload.error.code) {
        return {
          ...state,
          error: payload.error,
          loading: false,
        };
      }

      return {
        ...state,
        error: {
          code: ERROR.UNKNOWN,
          name: 'Something went wrong',
          description: 'We already fixing the problem. Please try again later'
        },
        loading: false
      };
    });
    builder.addCase(getRequestStatus.pending, (state) => {
      if (state.loading) return state;

      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getRequestStatus.fulfilled, (state, { payload }) => {
      if (!state.loading) return state;

      return {
        ...state,
        status: payload.status,
        offers: payload.offers,
        error: null,
        loading: false,
      };
    });
    builder.addCase(getRequestStatus.rejected, (state, { payload }) => {
      if (payload.error.code) {
        return {
          ...state,
          error: payload.error,
          loading: false,
        };
      }

      return {
        ...state,
        error: {
          code: ERROR.UNKNOWN,
          name: 'Something went wrong',
          description: 'We already fixing the problem. Please try again later'
        },
        loading: false
      };
    });
    builder.addCase(generateRequestDocuments.pending, (state) => {
      if (state.loading) return state;

      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(generateRequestDocuments.fulfilled, (state, { payload }) => {
      if (!state.loading) return state;

      return {
        ...state,
        error: payload.error || null,
        loading: false,
      };
    });
    builder.addCase(generateRequestDocuments.rejected, (state, { payload }) => {
      if (payload.error.code) {
        return {
          ...state,
          error: payload.error,
          loading: false,
        };
      }

      return {
        ...state,
        error: {
          code: ERROR.UNKNOWN,
          name: 'Something went wrong',
          description: 'We already fixing the problem. Please try again later'
        },
        loading: false
      };
    });
    builder.addCase(viewRequestDocuments.pending, (state) => {
      if (state.loading) return state;

      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(viewRequestDocuments.fulfilled, (state, { payload }) => {
      if (!state.loading) return state;

      return {
        ...state,
        error: payload.error || null,
        documents: payload,
        loading: false,
      };
    });
    builder.addCase(viewRequestDocuments.rejected, (state, { payload }) => {
      if (payload.error.code) {
        return {
          ...state,
          error: payload.error,
          loading: false,
        };
      }

      return {
        ...state,
        error: {
          code: ERROR.UNKNOWN,
          name: 'Something went wrong',
          description: 'We already fixing the problem. Please try again later'
        },
        loading: false
      };
    });
    builder.addCase(signContract.pending, (state) => {
      if (state.loading) return state;

      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(signContract.fulfilled, (state, { payload }) => {
      if (!state.loading) return state;

      return {
        ...state,
        error: payload.error || null,
        signSessionId: payload.signSessionId,
        loading: false,
      };
    });
    builder.addCase(signContract.rejected, (state, { payload }) => {
      if (payload.error.code) {
        return {
          ...state,
          error: payload.error,
          loading: false,
        };
      }

      return {
        ...state,
        error: {
          code: ERROR.UNKNOWN,
          name: 'Something went wrong',
          description: 'We already fixing the problem. Please try again later'
        },
        loading: false
      };
    });
    builder.addCase(getContractSignStatus.pending, (state) => {
      if (state.loading) return state;

      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getContractSignStatus.fulfilled, (state, { payload }) => {
      if (!state.loading) return state;

      return {
        ...state,
        error: payload.error || null,
        loading: false,
      };
    });
    builder.addCase(getContractSignStatus.rejected, (state, { payload }) => {
      if (payload.error.code) {
        return {
          ...state,
          error: payload.error,
          loading: false,
        };
      }

      return {
        ...state,
        error: {
          code: ERROR.UNKNOWN,
          name: 'Something went wrong',
          description: 'We already fixing the problem. Please try again later'
        },
        loading: false
      };
    });
  }
});

export const {
  updateRequestId,
  setCreditAmount,
  setInitialPayment,
  setSelectedOffer,
  clearPaymentErrors,
} = requestSlice.actions;

export const requestSelector = (state) => state.request;
export const selectedOfferSelector = (state) => state.request.selectedOffer;
export const requestIdSelector = (state) => state.request.requestId;
export const offerByIdSelector = (id) => (state) => state
  .request.offers.find((item) => item.id === id);

export default requestSlice.reducer;
