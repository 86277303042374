import styles from './Loader.module.scss';

const Loader = () => (
  <div
    className={ styles.loader }
    id="loader"
    v-if="loading"
  >
    <span className={ styles.item } />
    <span className={ styles.delayedItem } />
  </div>
);

export default Loader;
