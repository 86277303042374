import classnames from 'classnames/bind';
import PropTypes from 'prop-types';

import * as styles from './Typography.module.scss';

const cn = classnames.bind(styles);

function Heading({
  size,
  type,
  marginTop,
  marginBottom,
  align,
  className,
  html,
  children
}) {
  const Component = type;

  const classes = cn(
    'heading',
    `heading--${ size || type }`,
    marginTop && `mt--${ marginTop }`,
    marginBottom && `mb--${ marginBottom }`,
    align && `align--${ align }`,
    className
  );

  return (
    <Component
      className={ classes }
      dangerouslySetInnerHTML={ html ? { __html: html } : undefined }
    >
      { children }
    </Component>
  );
}

Heading.propTypes = {
  size: PropTypes.oneOf([ 'h1', 'h2', 'h3' ]),
  type: PropTypes.oneOf([ 'h1', 'h2', 'h3', 'p', 'legend' ]),
  marginTop: PropTypes.oneOf([ null, '1', '2', '3', '4', 'auto' ]),
  marginBottom: PropTypes.oneOf([ null, '1', '2', '3', '4', 'auto' ]),
  align: PropTypes.oneOf([ null, 'left', 'right', 'center' ]),
  className: PropTypes.string,
  html: PropTypes.string,
  children: PropTypes.node,
};

Heading.defaultProps = {
  size: null,
  type: 'p',
  marginTop: null,
  marginBottom: null,
  align: null,
  className: '',
  html: undefined,
  children: undefined
};

export default Heading;
