/* eslint-disable max-len */
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Button from '~components/Button';
import Modal from '~components/Modal';
import { Heading } from '~components/Typography';

import { errors } from '~data/en';

import * as styles from './ErrorModal.module.scss';

const ErrorModal = ({ error, children, onClose }) => {
  const modalRef = useRef();

  const handleClose = () => {
    modalRef.current.closeModal();

    onClose();
  };

  useEffect(() => {
    if (!modalRef.current) return;

    if (error && !modalRef.current.isOpen) {
      modalRef.current.openModal();
    }

    if (!error && modalRef.current.isOpen) {
      modalRef.current.closeModal();
    }
  }, [ error ]);

  return (
    <Modal ref={ modalRef } label={ errors.modal.label }>
      {
        error && (
          <article className={ styles.error }>
            <header className={ styles.header }>
              <Heading type="h2">
                { error.name }
              </Heading>
            </header>

            <div className={ styles.content }>
              { error.description }
            </div>

            <footer className={ styles.footer }>
              {
                children || (
                  <Button
                    className={ styles.button }
                    onClick={ handleClose }
                    block
                  >
                    { errors.modal.close }
                  </Button>
                )
              }
            </footer>
          </article>
        )
      }
    </Modal>
  );
};

ErrorModal.propTypes = {
  error: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }),
  onClose: PropTypes.func,
  children: PropTypes.node,
};

ErrorModal.defaultProps = {
  error: null,
  onClose: () => {},
  children: null,
};

export default ErrorModal;
