import { createSlice } from '@reduxjs/toolkit';

import { ERROR } from '~shared/constants';

import { createCustomer, getAuthStatus, signInCustomer } from '../actions/customer';
import { signContract } from '../actions/request';

export const customerSlice = createSlice({
  name: 'customer',
  initialState: {
    error: null,
    loading: false,
    id: null,
    authSessionId: null,
    personalId: '',
    email: '',
    phone: '',
  },
  reducers: {
    updateCustomer(state, { payload }) {
      return {
        ...state,
        ...payload
      };
    },
    clearVerificationCode(state) {
      return {
        ...state,
        verificationCode: null
      };
    },
    clearCustomerErrors(state) {
      return {
        ...state,
        error: null
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createCustomer.pending, (state) => {
      if (state.loading) return state;

      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(createCustomer.fulfilled, (state, { payload }) => {
      if (!state.loading) return state;

      if (payload.error) {
        return {
          ...state,
          error: payload.error,
          loading: false,
        };
      }

      return {
        ...state,
        id: payload.id,
        error: null,
        loading: false,
      };
    });
    builder.addCase(createCustomer.rejected, (state, { payload }) => {
      if (payload.error.code) {
        return {
          ...state,
          error: payload.error,
          loading: false,
        };
      }

      return {
        ...state,
        error: {
          code: ERROR.UNKNOWN,
          name: 'Something went wrong',
          description: 'We already fixing the problem. Please try again later'
        },
        loading: false
      };
    });
    builder.addCase(signInCustomer.pending, (state) => {
      if (state.loading) return state;

      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(signInCustomer.fulfilled, (state, action) => {
      if (!state.loading) return state;

      return {
        ...state,
        error: null,
        ...action.payload,
        loading: false,
      };
    });
    builder.addCase(signInCustomer.rejected, (state, { payload }) => {
      if (payload.error.code) {
        return {
          ...state,
          error: payload.error,
          loading: false,
        };
      }

      return {
        ...state,
        error: {
          code: ERROR.UNKNOWN,
          name: 'Something went wrong',
          description: 'We already fixing the problem. Please try again later'
        },
        loading: false
      };
    });
    builder.addCase(getAuthStatus.pending, (state) => {
      if (state.loading) return state;

      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getAuthStatus.fulfilled, (state, { payload }) => {
      if (!state.loading) return state;

      if (payload.status !== 6 && payload.status !== 1) {
        return {
          ...state,
          error: {
            code: ERROR.VERIFICATION_ERROR,
            name: 'Verification error',
            description: 'Unable to verify identity'
          },
          loading: false
        };
      }

      return {
        ...state,
        error: null,
        loading: false,
      };
    });
    builder.addCase(getAuthStatus.rejected, (state, { payload }) => {
      if (payload.error.code) {
        return {
          ...state,
          error: payload.error,
          loading: false,
        };
      }

      return {
        ...state,
        error: {
          code: ERROR.UNKNOWN,
          name: 'Something went wrong',
          description: 'We already fixing the problem. Please try again later'
        },
        loading: false
      };
    });
    builder.addCase(signContract.fulfilled, (state, { payload }) => ({
      ...state,
      verificationCode: payload.verificationCode,
    }));
  }
});

export const {
  updateCustomer,
  clearCustomerErrors,
  clearVerificationCode
} = customerSlice.actions;

export const customerSelector = (state) => state.customer;
export const personalIdSelector = (state) => state.customer.personalId;
export const customerIdSelector = (state) => state.customer.id;
export const verificationCodeSelector = (state) => state.customer.verificationCode;

export default customerSlice.reducer;
