/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '~shared/api';

export const getMerchantInfo = createAsyncThunk(
  'request/getMerchantInfo',
  (merchantId) => api.merchant.getMerchantInfo(merchantId),
  {
    condition: (merchantId, { getState }) => {
      const { merchant } = getState();

      if (merchant.loading) {
        return false;
      }
    },
  }
);

export const createRequest = createAsyncThunk(
  'request/createRequest',
  (props, { getState }) => {
    const { merchant, customer, request } = getState();

    return api.request.createRequest({
      merchant: merchant.id,
      customer,
      request,
    });
  }
);

export const getRequestStatus = createAsyncThunk(
  'request/getRequestStatus',
  (props, { getState }) => {
    const { request, customer } = getState();

    return api.request.getRequestStatus({
      requestId: request.requestId,
      customerId: customer.id,
    });
  }
);

export const generateRequestDocuments = createAsyncThunk(
  'request/generateRequestDocuments',
  ({ documentType, documentNumber }, { getState }) => {
    const { request, customer } = getState();

    return api.request.generateRequestDocuments({
      requestId: request.requestId,
      customerId: customer.id,
      offerId: request.selectedOffer,
      documentType,
      documentNumber
    });
  }
);

export const viewRequestDocuments = createAsyncThunk(
  'request/viewRequestDocuments',
  (props, { getState }) => {
    const { request, customer } = getState();

    return api.request.viewRequestDocuments({
      requestId: request.requestId,
      customerId: customer.id,
      offerId: request.selectedOffer
    });
  }
);

export const signContract = createAsyncThunk(
  'request/signContract',
  (props, { getState }) => {
    const { request, customer } = getState();

    return api.request.signContract({
      requestId: request.requestId,
      customerId: customer.id,
      offerId: request.selectedOffer
    });
  }
);

export const getContractSignStatus = createAsyncThunk(
  'request/getContractSignStatus',
  (props, { getState }) => {
    const { request, customer } = getState();

    return api.request.getContractSignStatus({
      requestId: request.requestId,
      customerId: customer.id,
      offerId: request.selectedOffer,
      signSessionId: request.signSessionId
    });
  }
);
