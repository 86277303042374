import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useEmblaCarousel } from 'embla-carousel/react';
import PropTypes from 'prop-types';

import * as styles from './Carousel.module.scss';

const Carousel = React.forwardRef(({ initialSlide, children }, ref) => {
  const [ viewportRef, embla ] = useEmblaCarousel({
    loop: true,
    skipSnaps: false,
    startIndex: initialSlide
  });
  const [ selectedIndex, setSelectedIndex ] = useState(0);

  const slidesCount = React.Children.count(children);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [ embla ]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [ embla ]);
  const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [ embla ]);
  const canScrollPrev = useCallback(() => embla && embla.canScrollPrev(), [ embla ]);
  const canScrollNext = useCallback(() => embla && embla.canScrollNext(), [ embla ]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
  }, [ embla, setSelectedIndex ]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on('select', onSelect);
  }, [ embla, onSelect ]);

  useImperativeHandle(ref, () => ({
    scrollPrev,
    scrollNext,
    scrollTo,
    canScrollPrev,
    canScrollNext
  }));

  return (
    <div className={ styles.carousel }>
      <div className={ styles.container }>
        <div className={ styles.viewport } ref={ viewportRef }>
          <div className={ styles.inner }>
            {
              React.Children.map(children, (child) => (
                <div className={ styles.slide }>
                  { child }
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <div className={ styles.scrollbar }>
        <div
          className={ styles.indicator }
          style={
            {
              transform: `translateX(${ selectedIndex * 100 }%)`,
              width: `${ 100 / slidesCount }%`
            }
          }
        />
      </div>
    </div>
  );
});

Carousel.propTypes = {
  initialSlide: PropTypes.number,
  children: PropTypes.node
};

Carousel.defaultProps = {
  initialSlide: 0,
  children: []
};

export default Carousel;
