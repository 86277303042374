import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';

import Button from '~components/Button';
import ErrorModal from '~components/ErrorModal';
import { InputField } from '~components/Input';
import { useLoader } from '~components/Loader';
import { SelectField } from '~components/Select';
import { Heading } from '~components/Typography';
import VerificationModal from '~components/VerificationModal';

import {
  generateRequestDocuments,
  getContractSignStatus,
  signContract,
  viewRequestDocuments,
} from '~store/actions/request';
import { clearVerificationCode, verificationCodeSelector } from '~store/reducers/customer';
import { clearPaymentErrors,
  offerByIdSelector,
  requestSelector } from '~store/reducers/request';
import useScrollRestoration from '~shared/helpers/hooks/useScrollRestoration';

import { contractSigning } from '~data/en';

import validationSchema from './validationSchema';

import * as styles from './SignContract.module.scss';

function SignContract() {
  const documentsCheckInterval = useRef(0);

  const [ documentsGenerationStatus, setDocumentsGenerationStatus ] = useState('not started');

  useScrollRestoration();

  const { setLoading } = useLoader();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    error,
    loading,
    product,
    selectedOffer,
    documents,
  } = useSelector(requestSelector);
  const verificationCode = useSelector(verificationCodeSelector);
  const currentOffer = useSelector(offerByIdSelector(selectedOffer));

  const handleDocumentsFetch = async (values, actions) => {
    clearInterval(documentsCheckInterval.current);

    setLoading(true);

    setDocumentsGenerationStatus('started');

    dispatch(generateRequestDocuments(values))
      .then((response) => {
        if (response.payload.error) {
          setLoading(false);

          return;
        }

        documentsCheckInterval.current = setInterval(() => {
          dispatch(viewRequestDocuments())
            .then((res) => {
              if (res.payload.length > 0) {
                setLoading(false);
                clearInterval(documentsCheckInterval.current);
                setDocumentsGenerationStatus('finished');
              }
            })
            .catch(() => {
              setLoading(false);
            })
            .finally(() => {
              actions.setSubmitting();
            });
        }, 1000);
      });
  };

  const handleSignAgreement = () => {
    setLoading(true);

    dispatch(signContract())
      .finally(() => {
        setLoading(false);
      });
  };

  const handleVerificationStart = () => dispatch(getContractSignStatus()).then((res) => {
    if (res.payload.status === 0) return Promise.resolve(0);
    if (res.payload.status === 1) return Promise.resolve(1);

    return Promise.resolve(-1);
  });
  const handleVerificationComplete = () => history.push('/succeed');
  const handleVerificationError = () => history.push('/rejected');
  const handleVerificationEnd = () => dispatch(clearVerificationCode());

  const renderDocuments = documents.length > 0 ? documents.map((item) => (
    <li key={ item.file }>
      <Button
        className={ styles.documentLink }
        href={ item.file }
        variant="secondary"
        icon="pdf"
        target="_blank"
        block
      >
        { item.type }
      </Button>
    </li>
  )) : null;

  const handleErrorModalClose = () => {
    dispatch(clearPaymentErrors());
  };

  useEffect(() => () => {
    clearInterval(documentsCheckInterval.current);
  }, []);

  return (
    <div className={ styles.container }>
      <Heading
        type="h1"
        size="h2"
        marginBottom="2"
        html={ contractSigning.heading }
      />

      <div className={ styles.summary }>
        <Heading
          type="h2"
          size="h3"
          className={ styles.subheading }
          html={ contractSigning.summary.heading }
        />

        <table className={ styles.table }>
          <tbody>
            <tr>
              <td>{ contractSigning.summary.product }</td>
              <td>{ product }</td>
            </tr>
            <tr>
              <td>{ contractSigning.summary.monthlyPayment }</td>
              <td>
                { contractSigning.summary.currency }
                {currentOffer.paymentAmount}
              </td>
            </tr>
            <tr>
              <td>{ contractSigning.summary.paymentsCount }</td>
              <td>{ currentOffer.payments }</td>
            </tr>
            {/* <tr>
            <td>{ contractSigning.summary.nextPaymentDate }</td>
            <td>12.12.2020</td>
          </tr>
          <tr>
            <td>{ contractSigning.summary.nextPaymentDate }</td>
            <td>12.45%</td>
          </tr> */}
          </tbody>
        </table>
      </div>

      <Heading
        type="h2"
        marginTop="4"
        marginBottom="2"
        html={ contractSigning.documentsForm.heading }
      />

      <Formik
        initialValues={
          {
            documentType: null,
            documentNumber: '',
          }
        }
        onSubmit={ handleDocumentsFetch }
        validationSchema={ validationSchema }
        validateOnMount
      >
        {
          ({ isValid, isSubmitting }) => (
            <Form className={ styles.document }>
              <SelectField
                name="documentType"
                placeholder={ contractSigning.documentsForm.fields.documentType.label }
                options={ contractSigning.documentsForm.fields.documentType.options }
              />

              <InputField
                name="documentNumber"
                placeholder={ contractSigning.documentsForm.fields.documentNumber.label }
                autoComplete="off"
              />

              <Button
                type="submit"
                variant="outline"
                block
                disabled={ !isValid || isSubmitting || documentsGenerationStatus === 'started' }
              >
                { contractSigning.documentsForm.submit }
              </Button>
            </Form>
          )
        }
      </Formik>

      {
        documentsGenerationStatus === 'finished' && (
          <>
            <Heading
              type="h2"
              marginTop="4"
              marginBottom="2"
              html={ contractSigning.documentsView.heading }
            />

            <ul className={ styles.links }>
              { documents.length > 0 && renderDocuments}
            </ul>

            <Heading
              type="h2"
              marginTop="4"
              marginBottom="2"
              html={ contractSigning.sign.heading }
            />

            <div className={ styles.sign }>
              <button
                type="button"
                className={ styles.provider }
                onClick={ handleSignAgreement }
                disabled={ loading }
              >
                <img
                  src={ contractSigning.sign.smartId.picture[1] }
                  srcSet={ `${ contractSigning.sign.smartId.picture[0] } 2x, ${ contractSigning.sign.smartId.picture[1] }` }
                  alt={ contractSigning.sign.smartId.label }
                  height="48"
                />
              </button>
              {/* <Button
                to="/success"
                block
              >
                { contractSigning.sign.submit }
              </Button> */}
            </div>
          </>
        )
      }

      <VerificationModal
        verificationCode={ verificationCode }
        onVerificationStart={ handleVerificationStart }
        onVerificationComplete={ handleVerificationComplete }
        onVerificationError={ handleVerificationError }
        onVerificationEnd={ handleVerificationEnd }
      />

      <ErrorModal
        error={ error }
        onClose={ handleErrorModalClose }
      />
    </div>
  );
}

export default SignContract;
