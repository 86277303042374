import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';

import Carousel from '~components/Carousel';
import ErrorModal from '~components/ErrorModal/ErrorModal';
import Icon from '~components/Icon';
import { Heading } from '~components/Typography';

import { getMerchantInfo } from '~store/actions/request';
import { clearMerchantErrors, merchantSelector } from '~store/reducers/merchant';
import useScrollRestoration from '~shared/helpers/hooks/useScrollRestoration';

import { onboarding } from '~data/en';

import * as styles from './Onboarding.module.scss';

function Onboarding() {
  const errorModalRef = useRef();

  useScrollRestoration();

  const history = useHistory();
  const { merchantId } = useParams();

  const dispatch = useDispatch();
  const { logo, error } = useSelector(merchantSelector);

  const carouselRef = useRef();

  const handleNext = (e) => {
    e.preventDefault();

    if (!merchantId) return;

    if (error) {
      errorModalRef.current.openModal();
    }

    history.push('/invoice-amount');
  };

  useEffect(() => {
    if (merchantId && !logo) {
      dispatch(getMerchantInfo(merchantId));
    }
  }, [ merchantId, logo ]);

  useEffect(() => {
    if (!errorModalRef.current) return;

    if (error && !errorModalRef.current.isOpen) {
      errorModalRef.current.openModal();
    }

    if (!error && errorModalRef.current.isOpen) {
      errorModalRef.current.closeModal();
    }
  }, [ error ]);

  const handleErrorModalClose = () => {
    dispatch(clearMerchantErrors());
  };

  const renderScreens = onboarding.screens.map(({ heading, picture }) => (
    <div key={ heading } className={ styles.screen }>
      <div className={ styles.illustration }>
        <img src={ picture.src } alt={ picture.alt } />
      </div>

      <div className={ styles.content }>
        <Heading size="h1" align="center" html={ heading } />
      </div>
    </div>
  ));

  return (
    <div className={ styles.onboarding }>
      <Carousel ref={ carouselRef }>
        { renderScreens }
      </Carousel>

      {
        merchantId && (
          <Link className={ styles.buttonNext } to="/invoice-amount" onClick={ handleNext }>
            <Icon icon="chevron-right" />
          </Link>
        )
      }

      <ErrorModal
        error={ error }
        onClose={ handleErrorModalClose }
      />
    </div>
  );
}

export default Onboarding;
