import { Heading, Paragraph } from '~components/Typography';

import useScrollRestoration from '~shared/helpers/hooks/useScrollRestoration';

import { successPage } from '~data/en';

import * as styles from './Result.module.scss';

function Success() {
  useScrollRestoration();

  return (
    <div className={ styles.result }>
      <div className={ styles.screen }>
        <img
          className={ styles.illustration }
          src={ successPage.picture.src }
          alt={ successPage.picture.alt }
          width="247"
          height="247"
        />

        <div className={ styles.content }>

          <Heading
            type="h1"
            marginTop="2"
            html={ successPage.heading }
          />
          <Paragraph
            marginTop="1"
            html={ successPage.description }
          />
        </div>
      </div>
    </div>
  );
}

export default Success;
