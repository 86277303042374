import { useLayoutEffect, useRef } from 'react';
import Mask from 'imask';
import PropTypes from 'prop-types';

import Input from './Input';

const MaskedInput = ({ mask, name, value, onChange, ...restProps }) => {
  const inputRef = useRef();

  useLayoutEffect(() => {
    const maskHandle = Mask(inputRef.current, { mask,
      lazy: true });

    const handleChange = () => {
      onChange(maskHandle.value);
    };

    maskHandle.on('accept', handleChange);

    return () => {
      maskHandle.off('accept', handleChange);
    };
  }, []);

  return (
    <Input
      ref={ inputRef }
      name={ name }
      { ...restProps }
    />
  );
};

MaskedInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  mask: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  onChange: PropTypes.func.isRequired
};

MaskedInput.defaultProps = {
  value: ''
};

export default MaskedInput;
