import { Helmet } from 'react-helmet';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

import AdditionalInfo from '~pages/AdditionalInfo';
import { Register, SignIn } from '~pages/Auth';
import BackLink from '~pages/BackLink';
import InitialPayment from '~pages/InitialPayment';
import NotFound from '~pages/NotFoundPage';
import Onboarding from '~pages/Onboarding';
import { Rejected, Success } from '~pages/Result';
import SignContract from '~pages/SignContract';
import SplitPayment from '~pages/SplitPayment';
import Start from '~pages/Start';
import Verification from '~pages/Verification';

import Header from '~components/Header';
import { withLoader } from '~components/Loader';
import NavigationGuard from '~components/NavigationGuard';

import { customerIdSelector, personalIdSelector } from '~store/reducers/customer';
import { merchantIdSelector } from '~store/reducers/merchant';
import { requestIdSelector, selectedOfferSelector } from '~store/reducers/request';

import { meta } from '~data/en';

import * as styles from './App.module.scss';

function App() {
  return (
    <Router>
      <div className={ styles.app }>
        <Helmet>
          <title>{ meta.title }</title>
          <meta name="description" content={ meta.description } />
        </Helmet>

        <Header />

        <main className={ styles.main }>
          <Switch>
            <Route exact path="/">
              <Onboarding />
            </Route>
            <Route exact path="/start/:merchantId">
              <NavigationGuard param="merchantId" selector={ merchantIdSelector }>
                <Onboarding />
              </NavigationGuard>
            </Route>
            <Route path="/invoice-amount">
              <NavigationGuard selector={ merchantIdSelector }>
                <Start />
              </NavigationGuard>
            </Route>
            <Route path="/auth/signin">
              <NavigationGuard selector={ merchantIdSelector }>
                <SignIn />
              </NavigationGuard>
            </Route>
            <Route path="/auth/register">
              <NavigationGuard selector={ merchantIdSelector }>
                <Register />
              </NavigationGuard>
            </Route>
            <Route path="/contract/verification">
              <NavigationGuard selector={ merchantIdSelector }>
                <NavigationGuard selector={ personalIdSelector }>
                  <Verification />
                </NavigationGuard>
              </NavigationGuard>
            </Route>
            <Route path="/contract/additional-info">
              <NavigationGuard selector={ merchantIdSelector }>
                <NavigationGuard selector={ personalIdSelector }>
                  <AdditionalInfo />
                </NavigationGuard>
              </NavigationGuard>
            </Route>
            <Route path="/contract/initial-payment">
              <NavigationGuard selector={ merchantIdSelector }>
                <NavigationGuard selector={ customerIdSelector }>
                  <InitialPayment />
                </NavigationGuard>
              </NavigationGuard>
            </Route>
            <Route path="/contract/status">
              <NavigationGuard param="customerId" selector={ customerIdSelector }>
                <NavigationGuard param="requestId" selector={ requestIdSelector }>
                  <BackLink />
                </NavigationGuard>
              </NavigationGuard>
            </Route>
            <Route path="/contract/offers">
              <NavigationGuard param="customerId" selector={ customerIdSelector }>
                <NavigationGuard param="requestId" selector={ requestIdSelector }>
                  <SplitPayment />
                </NavigationGuard>
              </NavigationGuard>
            </Route>
            <Route path="/contract/sign">
              <NavigationGuard param="customerId" selector={ customerIdSelector }>
                <NavigationGuard param="requestId" selector={ requestIdSelector }>
                  <NavigationGuard param="offerId" selector={ selectedOfferSelector }>
                    <SignContract />
                  </NavigationGuard>
                </NavigationGuard>
              </NavigationGuard>
            </Route>
            <Route path="/succeed">
              <Success />
            </Route>
            <Route path="/rejected">
              <Rejected />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </main>
      </div>
    </Router>
  );
}

export default withLoader(App);
