import classnames from 'classnames/bind';
import PropTypes from 'prop-types';

import * as styles from './Icon.module.scss';

const cn = classnames.bind(styles);

function Icon({ variant, icon, className }) {
  const classes = cn('icon', `icon--${ variant }`, className);

  return (
    <span className={ classes }>
      <svg className={ styles.svg }>
        <use href={ `/sprite.svg#${ icon }` } />
      </svg>
    </span>
  );
}

Icon.propTypes = {
  variant: PropTypes.oneOf([ 'primary', 'accent' ]),
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Icon.defaultProps = {
  variant: 'primary',
  className: ''
};

export default Icon;
