import { useField } from 'formik';

import Checkbox from './Checkbox';

function CheckboxField(props) {
  const [ field, meta ] = useField({ type: 'checkbox', ...props });

  return (
    <Checkbox
      { ...field }
      { ...props }
      error={ meta.touched && meta.error }
    />
  );
}

export default CheckboxField;
