import * as Yup from 'yup';

const DocumentsSchema = Yup.object().shape({
  documentType: Yup.object().shape({
    value: Yup.number(),
    label: Yup.string()
  }).required('Required'),
  documentNumber: Yup.string().required('Required'),
});

export default DocumentsSchema;
