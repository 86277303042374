import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import createTouch, { MOVE } from 'tinytouch';

import styles from './Modal.module.scss';

const cn = classnames.bind(styles);

ReactModal.setAppElement('#root');

const Modal = React.forwardRef(({
  className,
  transition,
  label,
  onOpen,
  onClose,
  closeOnOverlayClick,
  children,
}, ref) => {
  const openingTimeout = useRef(0);
  const closingTimeout = useRef(0);
  const slideTimeout = useRef(0);
  const dragZone = useRef();

  const [ isOpen, setOpen ] = useState(false);

  const [ opening, setOpening ] = useState(false);
  const [ closing, setClosing ] = useState(false);

  const [ offsetY, setOffsetY ] = useState(0);

  const portalClassname = cn('portal',);

  const overlayClassName = cn(
    'overlay',
    isOpen && 'is-open',
    `overlay--${ transition === 'fade' ? 'center' : 'bottom' }`,
    opening && 'overlay--opening',
    closing && 'overlay--closing',
    opening && transition === 'fade' && 'transition--fade-in',
    closing && transition === 'fade' && 'transition--fade-exit',
  );

  const contentClassName = cn(
    'content',
    `content--${ transition === 'fade' ? 'center' : 'bottom' }`,
    isOpen && 'is-open',
    `transition--${ transition }`,
    opening && `transition--${ transition }-in`,
    closing && `transition--${ transition }-exit`,
    className,
  );

  const handleOpen = () => {
    setOpening(true);
    setOpen(true);
    openingTimeout.current = setTimeout(() => setOpening(false), 350);
  };

  const handleClose = () => {
    setClosing(true);
    setOpen(false);
    closingTimeout.current = setTimeout(() => setClosing(false), 350);
  };

  useEffect(() => {
    if (transition !== 'slide-bottom') return;

    const touchHandler = createTouch(dragZone.current);

    const handleTouch = ({ ty, dx }) => {
      if (dx > 5) return;
      clearTimeout(slideTimeout.current);

      setOffsetY(ty);

      setTimeout(() => {
        if (ty > 100) {
          handleClose();
        } else {
          slideTimeout.current = setTimeout(() => {
            setOffsetY(0);
          }, 500);
        }
      }, 0);
    };

    touchHandler.on(MOVE, handleTouch);

    return () => {
      touchHandler.off(MOVE, handleTouch);
      clearTimeout(slideTimeout.current);
    };
  }, []);

  useEffect(() => () => {
    clearTimeout(openingTimeout.current);
    clearTimeout(closingTimeout.current);
  }, []);

  useImperativeHandle(ref, () => ({
    isOpen,
    openModal: handleOpen,
    closeModal: handleClose
  }));

  return (
    <ReactModal
      isOpen={ isOpen }
      style={
        { content: {
          transform: `translateY(${ offsetY }px)`
        } }
      }
      closeTimeoutMS={ 350 }
      onRequestClose={ handleClose }
      onAfterOpen={ onOpen }
      onAfterClose={ onClose }
      contentLabel={ label }
      portalClassName={ portalClassname }
      overlayClassName={ overlayClassName }
      shouldCloseOnEsc={ closeOnOverlayClick }
      shouldCloseOnOverlayClick={ closeOnOverlayClick }
      className={ contentClassName }
      bodyOpenClassName="body--modal-open"
      htmlOpenClassName="html--modal-open"
    >
      {
        transition === 'slide-bottom' && (
          <div ref={ dragZone } className={ styles.dragZone } />
        )
      }
      { children }
    </ReactModal>
  );
});

Modal.propTypes = {
  className: PropTypes.string,
  transition: PropTypes.oneOf([ 'fade', 'slide-bottom' ]),
  label: PropTypes.string.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  closeOnOverlayClick: PropTypes.bool,
  children: PropTypes.node,
};

Modal.defaultProps = {
  className: '',
  transition: 'fade',
  onOpen: null,
  onClose: null,
  closeOnOverlayClick: true,
  children: null
};

export default Modal;
