import classnames from 'classnames/bind';
import PropTypes from 'prop-types';

import Icon from '~components/Icon';

import * as styles from './Checkbox.module.scss';

const cn = classnames.bind(styles);

function Checkbox({ error, className, children, ...restProps }) {
  const controlClasses = cn(
    'control',
    error && 'control--error',
    className,
  );

  return (
    <label className={ controlClasses }>
      <input type="checkbox" className={ styles.input } { ...restProps } />

      <Icon className={ styles.check } icon="checkbox" />
      <Icon className={ styles.box } icon="checkbox-outline" />

      <span className={ styles.label }>{ children }</span>
    </label>
  );
}

Checkbox.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

Checkbox.defaultProps = {
  error: false,
  className: ''
};

export default Checkbox;
