import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';

import Button from '~components/Button';
import ErrorModal from '~components/ErrorModal';
import Icon from '~components/Icon';
import { InputField } from '~components/Input';
// import SocialAuth from '~components/SocialAuth';
import TermsModal from '~components/TermsModal';
import {
  // Caption,
  Heading,
  Paragraph
} from '~components/Typography';

import { clearCustomerErrors, customerSelector, updateCustomer } from '~store/reducers/customer';
import useScrollRestoration from '~shared/helpers/hooks/useScrollRestoration';

import { auth } from '~data/en';

import validationSchema from './validationSchema';

import * as styles from './Auth.module.scss';

// Copy of Sign In page
function Register() {
  const termsModalRef = useRef();

  useScrollRestoration();

  const dispatch = useDispatch();
  const { error } = useSelector(customerSelector);

  const history = useHistory();

  const handleTermsOpen = () => {
    termsModalRef.current.openTerms();
  };

  const handleTermsAccepted = () => {
    history.push('/contract/verification');
  };

  const handleSubmit = (values, actions) => {
    dispatch(updateCustomer({ ...values }));
    actions.setSubmitting(false);
  };

  const handleErrorModalClose = () => {
    dispatch(clearCustomerErrors());
  };

  return (
    <div className={ styles.auth }>
      <Heading type="h1" html={ auth.heading } />
      <Paragraph marginTop="1" html={ auth.description } />

      <Formik
        validateOnMount
        initialValues={ { email: '', phone: '', personalId: '' } }
        validationSchema={ validationSchema }
        onSubmit={ handleSubmit }
      >
        {
          ({ isValid, isSubmitting }) => (
            <Form className={ styles.form }>
              <div className={ styles.group }>
                <InputField
                  startAdornment={
                    (
                      <Icon
                        className={ styles.adornment }
                        icon="message"
                        variant="accent"
                      />
                    )
                  }
                  type="email"
                  mask={ auth.field.email.mask }
                  placeholder={ auth.field.email.label }
                  required
                />
              </div>

              <div className={ styles.group }>
                <InputField
                  startAdornment={
                    (
                      <Icon
                        className={ styles.adornment }
                        icon="phone"
                        variant="accent"
                      />
                    )
                  }
                  name="phone"
                  mask={ auth.field.phone.mask }
                  placeholder={ auth.field.phone.label }
                  required
                />
              </div>

              <div className={ styles.group }>
                <InputField
                  startAdornment={
                    (
                      <Icon
                        className={ styles.adornment }
                        icon="document"
                        variant="accent"
                      />
                    )
                  }
                  name="personalId"
                  mask={ auth.field.personalId.mask }
                  placeholder={ auth.field.personalId.label }
                  required
                />
              </div>

              <div className={ styles.submit }>
                <Button
                  type="submit"
                  block
                  onClick={ handleTermsOpen }
                  disabled={ !isValid || isSubmitting }
                >
                  { auth.submit }
                </Button>
              </div>
            </Form>
          )
        }
      </Formik>

      {/* <Caption
        marginTop="1"
        marginBottom="1"
        html={ auth.socials.label }
      />

      <SocialAuth /> */}

      <ErrorModal
        error={ error }
        onClose={ handleErrorModalClose }
      />

      <TermsModal ref={ termsModalRef } onAccept={ handleTermsAccepted } />
    </div>
  );
}

export default Register;
