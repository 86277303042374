import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '~shared/api';

export const signInCustomer = createAsyncThunk(
  'customer/signIn',
  (props, { getState }) => {
    const { personalId } = getState().customer;

    if (!personalId) return;

    return api.customer.authStart(personalId);
  }
);

export const getAuthStatus = createAsyncThunk(
  'customer/getAuthStatus',
  (props, { getState }) => {
    const { authSessionId } = getState().customer;

    if (!authSessionId) return;

    return api.customer.getAuthStatus(authSessionId);
  }
);

export const createCustomer = createAsyncThunk(
  'customer/createCustomer',
  async (customerData) => {
    const {
      authSessionId,
      city,
      zip,
      street,
      email,
      occupation,
      monthlyIncome,
      monthlyExpenses,
      coreBank,
      pep,
      consent,
      phone,
    } = customerData;

    return api.customer.createCustomer({
      authSessionId,
      city,
      zip,
      street,
      email,
      occupation,
      monthlyIncome,
      monthlyExpenses,
      coreBank,
      pep,
      consent,
      phone,
    });
  }
);
